import { EppoClient, VariationType } from '@eppo/js-client-sdk-common'
import { DefaultSubjectAttributes } from '../../shared/DefaultSubjectAttributes'

export type GetAllExperimentVariants = (params: {
  subjectAttributes?: Record<string, string | number | boolean>
}) => Record<string, string | boolean | number>

export const setupGetAllExperimentVariants = ({
  client,
  subjectKey,
  defaultAttributes,
  overrides,
}: {
  client: EppoClient | null
  subjectKey: string
  defaultAttributes: DefaultSubjectAttributes
  overrides: { [key: string]: string }
}) => {
  const getAllExperimentVariants: GetAllExperimentVariants = ({
    subjectAttributes,
  }) => {
    if (!client) return {}

    const allFlags = client.getFlagKeys()
    const flagConfiguration = client.getFlagConfigurations()
    const experiments: Record<string, string> = {}
    const attributes = {
      ...defaultAttributes,
      ...subjectAttributes,
    }

    const overrideKeys = Object.keys(overrides)

    allFlags.forEach(flagKey => {
      const { variationType } = flagConfiguration[flagKey]

      const shouldOverride = overrideKeys.includes(flagKey)

      if (shouldOverride) {
        experiments[flagKey] = overrides[flagKey]
        return
      }

      switch (variationType) {
        case VariationType.STRING:
          experiments[flagKey] = client.getStringAssignment(
            flagKey,
            subjectKey,
            attributes,
            'control',
          )
          break
        case VariationType.BOOLEAN:
          experiments[flagKey] = client
            .getBooleanAssignment(flagKey, subjectKey, attributes, false)
            .toString()
          break
        case VariationType.INTEGER:
          experiments[flagKey] = client
            .getIntegerAssignment(flagKey, subjectKey, attributes, 0)
            .toString()
          break
      }
    })

    return experiments
  }
  return getAllExperimentVariants
}
