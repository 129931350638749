import { useMemo } from 'react'
import { useRouter } from '@moonpig/web-core-routing'
import { logger } from '@moonpig/web-core-monitoring'
import { parse } from '../../utils/parse-params/parse-params'

type UseQueryStringOverrideParams = {
  experimentKey: string
}

export const useQueryStringOverride = ({
  experimentKey,
}: UseQueryStringOverrideParams) => {
  const router = useRouter()

  return useMemo(() => {
    if (!router || typeof router.getCurrentRoute !== 'function') {
      return null
    }

    try {
      const { params } = router.getCurrentRoute() as {
        params: { variation?: string[] }
      }
      const overrides = parse(params?.variation)

      if (Object.keys(overrides).includes(experimentKey)) {
        return overrides[experimentKey]
      }

      return null
    } catch (error) {
      logger.warning(
        'Error retreiving overrides: useQueryStringOverride',
        {},
        error,
      )
      return null
    }
  }, [experimentKey, router])
}
