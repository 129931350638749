import { SearchInspirationType } from '..'

export const MAX_CARD_INSPIRATIONS = 5
export const MAX_GIFT_INSPIRATIONS = 3
export const MAX_FLOWER_INSPIRATIONS = 2

export const groupAndParseInspirations = (
  inspirationsArray: SearchInspirationType[],
): SearchInspirationType[] => {
  if (inspirationsArray.length === 0) return []

  const groupedInspirations = inspirationsArray.reduce<
    Record<string, SearchInspirationType[]>
  >((accumulator, inspiration) => {
    const { department } = inspiration
    if (!accumulator[department]) {
      accumulator[department] = []
    }
    accumulator[department].push(inspiration)
    return accumulator
  }, {})

  const cards =
    groupedInspirations.ALL_CARDS?.slice(0, MAX_CARD_INSPIRATIONS) || []
  const gifts =
    groupedInspirations.ALL_GIFTS?.slice(0, MAX_GIFT_INSPIRATIONS) || []
  const flowers =
    groupedInspirations.ALL_FLOWERS_AND_PLANTS?.slice(
      0,
      MAX_FLOWER_INSPIRATIONS,
    ) || []

  const result = [...cards, ...gifts, ...flowers]

  return result
}
