export type SendCustomerEvent = {
  sendEvent: boolean
  loginMethod?:
    | 'email'
    | 'google'
    | 'apple'
    | 'google one tap'
    | 'password'
    | 'magic link'
}

const tryGetItem = (key: string) => {
  try {
    return localStorage?.getItem(key)
  } catch (e) {
    return null
  }
}

const tryParse = <T extends Record<string, unknown>>(data: string): T => {
  try {
    return JSON.parse(data)
  } catch (e) {
    return {} as T
  }
}

const localStorageKey = 'mnpg_send_ga_user_data'

export const getSendCustomerEvent = (): SendCustomerEvent => {
  const sendGAUserDataString = tryGetItem(localStorageKey)
  if (!sendGAUserDataString) {
    return { sendEvent: false }
  }

  const { loginMethod = 'password' } = tryParse<{
    loginMethod: SendCustomerEvent['loginMethod']
  }>(sendGAUserDataString)
  return { sendEvent: true, loginMethod }
}

export const setSendCustomerEvent = (data: { loginMethod: string }) => {
  try {
    localStorage?.setItem(localStorageKey, JSON.stringify(data))
  } catch (e) {
    return null
  }
}

export const clearSendCustomerEvent = () => {
  try {
    localStorage?.removeItem(localStorageKey)
  } catch (e) {
    return null
  }
}
