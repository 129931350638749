import React, { FC } from 'react'
import { styled, breakpoint } from '@moonpig/launchpad-utils'
import { NAVIGATION_ICONS } from '@moonpig/web-core-brand/config'
import { NAV_BP } from '../constants'

const StyledIconContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 16px;
  width: 24px;
  height: 26px;
  transform: translateY(-50%);

  ${breakpoint(NAV_BP)} {
    display: none;
  }
`

export const NavigationIcon: FC<{ icon: string; label: string }> = ({
  icon,
  label,
}) => {
  const Icon = NAVIGATION_ICONS[icon]

  if (!Icon) return null

  return (
    <StyledIconContainer aria-hidden data-testid="navigation-icon">
      <Icon aria-label={label} />
    </StyledIconContainer>
  )
}
