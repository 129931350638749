import React from 'react'
import { styled, breakpointDown } from '@moonpig/launchpad-utils'
import {
  Modal,
  SecondaryButton,
  Tabs,
  Tab,
  TabList,
  TabPanels,
  TabPanel,
} from '@moonpig/launchpad-components'
import { useCoreFlag } from '@moonpig/web-core-flags'
import { FlagsList } from './FlagsList'

const StyledWidgetToggle = styled(SecondaryButton)`
  position: fixed;
  top: 50%;
  right: 0;
  width: 48px;
  height: 48px;
  font-size: 24px;
  transition: transform 200ms;
  transform: translate(20px, -50%);
  z-index: 3;

  &:hover {
    transform: translate(0, -50%);
  }

  ${breakpointDown('md')} {
    top: auto;
    bottom: 0;
    transform: none;

    &:hover {
      transform: none;
    }
  }
`

const StyledModalContainer = styled.div`
  width: 800px;
  height: 100vh;

  ${breakpointDown('md')} {
    width: calc(100vw - 24px);
  }
`

export const DevWidget: React.FC = () => {
  const tabs = ['Flags']
  const [selectedTab, setSelectedTab] = React.useState('Flags')
  const [isOpen, setIsOpen] = React.useState(false)
  const showDevWidget = useCoreFlag('show-dev-widget')

  return (
    <>
      {!isOpen && showDevWidget ? (
        <StyledWidgetToggle
          aria-label="Open dev widget"
          onClick={() => setIsOpen(true)}
        >
          <span aria-hidden="true">🛠️</span>
        </StyledWidgetToggle>
      ) : null}
      <Modal
        label="Dev Widget"
        isOpen={isOpen}
        onDismiss={() => setIsOpen(false)}
        hasCloseButton
        closeButtonLabel="Close"
      >
        <StyledModalContainer>
          <Tabs
            defaultValue="Flags"
            value={selectedTab}
            onValueChange={e => setSelectedTab(e)}
          >
            <TabList>
              {tabs.map(t => (
                <Tab value={t} key={t}>
                  {t}
                </Tab>
              ))}
            </TabList>

            <TabPanels>
              <TabPanel key="Flags" value="Flags">
                <FlagsList />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </StyledModalContainer>
      </Modal>
    </>
  )
}
