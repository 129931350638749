/* eslint-disable react/no-array-index-key */
import React, { FC, useMemo } from 'react'
import {
  Grid,
  Box,
  Container,
  Text,
  Divider,
} from '@moonpig/launchpad-components'
import { breakpointUp, styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { PaymentMethodsPerRegion } from '@moonpig/web-core-brand/config'
import { LinkList, Items as LinkListItems } from './LinkList'
import { Apps, ItemProps as AppItemProps } from './Apps'
import { RegionSelector, ItemProps as RegionItemProps } from './RegionSelector'
import { PaymentMethods } from './PaymentMethods'
import { Social, ItemProps as SocialItemProps } from './Social'
import { Brands } from '../Brands'
import { useLocaleText } from '../locale'

type FooterNavProps = {
  appItems: AppItemProps[]
  socialItems: SocialItemProps[]
  linkListItems: LinkListItems[]
  regionItems?: RegionItemProps[]
  paymentProviderItems?: PaymentMethodsPerRegion
  brandItems: React.ComponentProps<typeof Brands>['items']
}

const SPACING = 10

const StyledDivider = styled(Divider)`
  ${s({ my: { xs: 7, md: 10 }, p: 0 })}

  &:last-of-type {
    display: none;
  }

  ${breakpointUp('md')} {
    &:nth-of-type(2n-1) {
      display: none;
    }
  }
  ${breakpointUp('lg')} {
    display: none;
    &:nth-of-type(3n) {
      display: block;
    }
  }
`

export const FooterNav: FC<React.PropsWithChildren<FooterNavProps>> = ({
  regionItems,
  linkListItems,
  appItems,
  socialItems,
  paymentProviderItems,
  brandItems,
}) => {
  const RegionsComponent = useMemo(
    () => <RegionSelector items={regionItems} />,
    [regionItems],
  )
  const AppsComponent = useMemo(() => <Apps items={appItems} />, [appItems])
  const SocialComponent = useMemo(
    () => <Social items={socialItems} />,
    [socialItems],
  )
  const PaymentMethodsComponent = useMemo(
    () => <PaymentMethods providers={paymentProviderItems} />,
    [paymentProviderItems],
  )
  const BrandsComponent = useMemo(
    () => <Brands items={brandItems} />,
    [brandItems],
  )

  const t = useLocaleText()
  const elements = regionItems
    ? [
        PaymentMethodsComponent,
        AppsComponent,
        SocialComponent,
        RegionsComponent,
        BrandsComponent,
      ]
    : [PaymentMethodsComponent, AppsComponent, SocialComponent, BrandsComponent]

  return (
    <>
      <Box py={SPACING} bgcolor="colorBackground02" as={'section'}>
        <Container limitWidth>
          <LinkList items={linkListItems} />
        </Container>
      </Box>
      <Box
        bgcolor="colorBackground01"
        pt={{ xs: 7, md: 10 }}
        pb={{ xs: 4, md: 12 }}
        as={'section'}
      >
        <Container limitWidth>
          <Grid colGap={{ xs: 0, md: 5 }} justifyContent="left">
            {elements.map((component, index) => (
              <React.Fragment key={index}>
                <Box width={{ xs: 1, md: 1 / 2, lg: 1 / 3 }}>{component}</Box>
                <StyledDivider withMargin={false} />
              </React.Fragment>
            ))}
            <Box
              maxWidth="860px"
              mt={7}
              width={{ xs: 1, md: 1 / 2, lg: 1 / 3 }}
            >
              <Text typography="typeBodyCaption" mb={0}>
                {t('footer.copyright', String(new Date().getFullYear()))}
              </Text>
            </Box>
          </Grid>
        </Container>
      </Box>
    </>
  )
}
