import { z } from 'zod'
import { eventDataSchema, mediaDataSchema } from '../../common.schema'

export const MediaStartSchema = z.object({
  event: z.literal('media_start'),

  event_data: eventDataSchema,
  media_data: mediaDataSchema.optional(),
})

export type MediaStartEvent = z.infer<typeof MediaStartSchema>
