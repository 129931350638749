import { isServer } from '@moonpig/web-core-utils'
import { createMetrics, logger } from '@moonpig/web-core-monitoring'
import { DefaultSubjectAttributes } from '../../shared/DefaultSubjectAttributes'
import { EppoConfiguration } from '../../types'
import { setupGetAllExperimentVariants } from '../setupGetAllExperimentVariants/setupGetAllExperimentVariants'
import { setupGetExperimentVariant } from '../setupGetExperimentVariant/setupGetExperimentVariant'
import { parse } from '../../utils/parse-params/parse-params'
import { createEppoClient } from '../../browser'

type SetupEppo = {
  sessionId: string
  defaultAttributes: DefaultSubjectAttributes
  overrideParams: Array<string>
  overrideCookie?: string
}

const EPPO_REFRESH_INTERVAL = 30000
const EPPO_TIMEOUT = 1000
let eppoConfiguration: EppoConfiguration
let lastFetch: Date

export const setupEppo = async ({
  sessionId,
  defaultAttributes,
  overrideParams,
  overrideCookie,
}: SetupEppo) => {
  if (isServer) {
    const shouldFetch = lastFetch
      ? new Date().getTime() - lastFetch.getTime() >= EPPO_REFRESH_INTERVAL
      : true

    if (shouldFetch) {
      eppoConfiguration = await fetchEppoConfiguration()
    }
  }

  const client = createEppoClient({
    flagsConfiguration: eppoConfiguration,
  })

  const cookieOverrides: { [key: string]: string } = overrideCookie
    ? JSON.parse(overrideCookie)
    : {}

  const overrides: { [key: string]: string } = {
    ...parse(overrideParams),
    ...cookieOverrides,
  }

  const {
    getExperimentBoolean,
    getExperimentInteger,
    getExperimentJson,
    getExperimentNumeric,
    getExperimentString,
    trackedServerExperiments,
  } = setupGetExperimentVariant({
    client,
    subjectKey: sessionId,
    defaultAttributes,
    overrides,
  })

  const getAllExperimentVariants = setupGetAllExperimentVariants({
    client,
    subjectKey: sessionId,
    defaultAttributes,
    overrides,
  })

  return {
    eppoConfiguration,
    getExperimentBoolean,
    getExperimentInteger,
    getExperimentJson,
    getExperimentNumeric,
    getExperimentString,
    getAllExperimentVariants,
    trackedServerExperiments,
  }
}

const fetchEppoConfiguration = async () => {
  const eppoUrl = new URL('https://fscdn.eppo.cloud/api/flag-config/v1/config')
  eppoUrl.search = new URLSearchParams({
    apiKey: `${process.env.EPPO_API_SDK_KEY}`,
    sdkName: 'node-server-sdk',
    sdkVersion: '3.5.0',
  }).toString()

  const abortController = new AbortController()
  const timerId = setTimeout(() => {
    logger.fixToday(`EPPO reached timeout of ${EPPO_TIMEOUT}ms`)
    abortController.abort()
  }, EPPO_TIMEOUT)
  const { traceAsync, count } = createMetrics({ useXRay: false })

  try {
    const res = await traceAsync(
      { metricName: 'fetch-eppo-configuration' },
      async () => fetch(eppoUrl, { signal: abortController.signal }),
    )

    if (!res.ok) {
      throw new Error(`Failed to fetch EPPO configuration: ${res.statusText}`)
    }

    const json = await res.json()

    if (!json.flags) {
      throw new Error('Failed to fetch EPPO configuration: no flags found')
    }

    lastFetch = new Date()
    return json.flags
  } catch (error) {
    logger.fixToday('Failed to fetch EPPO configuration', error)
    if (!eppoConfiguration) {
      count({ metricName: 'empty-eppo-configuration' })
      return {}
    }
    return eppoConfiguration
  } finally {
    clearTimeout(timerId)
  }
}
