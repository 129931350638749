import { EventObject } from './schemaTypes'

export const createGenericEvent = ({
  eventAction,
  eventLabel,
  eventCategory,
}: {
  eventLabel: string
  eventAction: string
  eventCategory: string
}): EventObject => {
  return {
    event: 'generic_event',
    event_data: {
      category: eventCategory.toLowerCase(),
      action: eventAction.toLowerCase(),
      label: eventLabel.toLowerCase(),
      non_interaction: true,
    },
    content_data: {
      content_type: `${eventCategory.toLowerCase()} | ${eventAction.toLowerCase()} | ${eventLabel.toLowerCase()}`,
    },
  }
}
