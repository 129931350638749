import { z } from 'zod'
import { eventDataSchema } from '../../common.schema'

export const LoginSchema = z.object({
  event: z.literal('login'),

  event_data: eventDataSchema,
})

export type LoginEvent = z.infer<typeof LoginSchema>
