import { z } from 'zod'
import {
  errorDataSchema,
  eventDataSchema,
  userDataSchema,
  zodLoginMethodEnum,
} from '../../common.schema'

export const SocialAuthenticationExceptionSchema = z.object({
  event: z.literal('social_authentication_exception'),

  user_data: userDataSchema.extend({
    login_method: zodLoginMethodEnum,
  }),
  event_data: eventDataSchema,
  error_data: errorDataSchema,
})

export type SocialAuthenticationExceptionEvent = z.infer<
  typeof SocialAuthenticationExceptionSchema
>
