import React, { FC, useContext, createContext } from 'react'
import { EventObject } from '../../schemaTypes'

const TrackingContext = createContext<EventObject | null>(null)

type TrackingDataProviderProps = {
  eventData: EventObject
}

export const TrackingDataProvider: FC<
  React.PropsWithChildren<TrackingDataProviderProps>
> = ({ children, eventData }) => (
  <TrackingContext.Provider value={eventData}>
    {children}
  </TrackingContext.Provider>
)

export const useTrackingData = () => useContext(TrackingContext)
