import { z } from 'zod'
import {
  errorDataSchema,
  eventDataSchema,
  userDataSchema,
  zodLoginMethodEnum,
} from '../../common.schema'

export const LoginErrorSchema = z.object({
  event: z.literal('login_error'),

  user_data: userDataSchema.extend({
    login_method: zodLoginMethodEnum,
  }),
  event_data: eventDataSchema,
  error_data: errorDataSchema,
})

export type LoginErrorEvent = z.infer<typeof LoginErrorSchema>
