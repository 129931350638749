import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  FC,
  useEffect,
} from 'react'
import { getBrowserCookies } from '@moonpig/web-core-cookies'
import { isLoggedIn } from './utils'

type LoggedInContext = {
  loggedIn: boolean
  setLoggedIn: (loggedIn: boolean) => void
}

type LoggedInProviderProps = {
  value: {
    loggedIn: boolean
  }
}

/* istanbul ignore next */
const loggedInContext = createContext<LoggedInContext>({
  loggedIn: false,
  setLoggedIn: () => {},
})

export const useLoggedIn = () => useContext(loggedInContext)

export const LoggedInProvider: FC<
  React.PropsWithChildren<LoggedInProviderProps>
> = ({ value, children }) => {
  const [loggedIn, setLoggedIn] = useState(value.loggedIn)

  const contextValue = useMemo(() => ({ loggedIn, setLoggedIn }), [loggedIn])

  useEffect(() => {
    const checkAuthCookies = () => {
      const newLoggedIn = isLoggedIn(getBrowserCookies())
      setLoggedIn(newLoggedIn)
    }

    window.addEventListener('focus', checkAuthCookies)
    return () => window.removeEventListener('focus', checkAuthCookies)
  }, [])

  return (
    <loggedInContext.Provider value={contextValue}>
      {children}
    </loggedInContext.Provider>
  )
}
