import { z } from 'zod'
import { eventDataSchema, remindersDataSchema } from '../../common.schema'

export const EditReminderSchema = z.object({
  event: z.literal('edit_reminder'),

  event_data: eventDataSchema,
  reminder_data: remindersDataSchema,
})

export type EditReminderEvent = z.infer<typeof EditReminderSchema>
