import { IAssignmentEvent } from '@eppo/js-client-sdk-common'
import { AbtestEvent, trackGAEvent } from '@moonpig/web-core-analytics'
import { isServer } from '@moonpig/web-core-utils'

export const logAssignment = ({
  experiment,
  variation,
  subjectAttributes: { shouldLog },
}: IAssignmentEvent) => {
  if (!experiment) return null
  if (!shouldLog) return null
  if (!variation || isServer) return null

  trackExperiment({ experiment, variation })
}

export const trackExperiments = (
  experiments: Record<string, string | boolean | number | object>,
) => {
  Object.entries(experiments).forEach(([experiment, variation]) => {
    trackExperiment({ experiment, variation })
  })
}

type TrackExperimentArgs = {
  experiment: string
  variation: string | boolean | number | object
}

const trackExperiment = ({ experiment, variation }: TrackExperimentArgs) => {
  const variationString =
    typeof variation === 'object' ? JSON.stringify(variation) : `${variation}`

  const event: AbtestEvent = {
    event: 'abtest',
    event_data: {
      category: 'ab test',
      action: experiment,
      label: `${variationString} | exposure`,
      non_interaction: true,
      value: undefined,
    },
  }

  if (!isExperimentAlreadyTracked(event)) {
    trackGAEvent(event)
  }
}

const isExperimentAlreadyTracked = (abtestEvent: AbtestEvent) => {
  if (!window.dataLayer) {
    return false
  }

  const abtestEvents = window.dataLayer.filter(
    ({ event }) => event === 'abtest',
  )

  return abtestEvents.some(event => {
    return (
      event.event_data.action === abtestEvent.event_data.action &&
      event.event_data.label === abtestEvent.event_data.label
    )
  })
}
