import {
  TrackingEvent,
  EventObject,
  createNavigationEvent,
} from '@moonpig/web-core-analytics'
import { absToRelUrl } from '@moonpig/web-core-utils'
import { createLinkHref } from '@moonpig/web-core-link'
import { makeNavTrackingButton } from './makeNavTrackingButton'

export type NavItem = {
  url: string
  title: string
  icon: string
  items: {
    title: string
    url: string
    items: {
      title: string
      url: string
    }[]
  }[]
}

const buildTrackEvent = (label: string): EventObject => {
  return createNavigationEvent({
    action: 'header menu',
    label,
  })
}

const createHref = (url: string, trackEvent?: TrackingEvent) => {
  return createLinkHref({ url: absToRelUrl(url), trackEvent })
}

const buildTrackedNavItems = (items: NavItem[]) => {
  return items.map(itemLevel1 => ({
    title: itemLevel1.title,
    icon: itemLevel1.icon,
    href: createHref(itemLevel1.url, buildTrackEvent(itemLevel1.title)),
    buttonAs: makeNavTrackingButton(itemLevel1.title),
    dropdown: itemLevel1.items.map(itemLevel2 => ({
      title: itemLevel2.title,
      href: itemLevel2.url
        ? createHref(
            itemLevel2.url,
            buildTrackEvent(`${itemLevel1.title} | ${itemLevel2.title}`),
          )
        : undefined,
      buttonAs: makeNavTrackingButton(
        `${itemLevel1.title} | ${itemLevel2.title}`,
      ),
      items: itemLevel2.items.map(itemLevel3 => ({
        title: itemLevel3.title,
        href: createHref(
          itemLevel3.url,
          buildTrackEvent(
            `${itemLevel1.title} | ${itemLevel2.title} | ${itemLevel3.title}`,
          ),
        ),
      })),
    })),
  }))
}

export { buildTrackedNavItems }
