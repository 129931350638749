import { z } from 'zod'
import {
  ecommerceItemSchema,
  ecommerceSchema,
  eventDataSchema,
} from '../../common.schema'

export const AddToWishlistSchema = z.object({
  event: z.literal('add_to_wishlist'),

  event_data: eventDataSchema,
  ecommerce: ecommerceSchema.extend({
    items: z.array(ecommerceItemSchema),
  }),
})

export type AddToWishlistEvent = z.infer<typeof AddToWishlistSchema>
