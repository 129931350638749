import { z } from 'zod'
import { eventDataSchema, mediaDataSchema } from '../../common.schema'

export const MediaPauseSchema = z.object({
  event: z.literal('media_pause'),

  event_data: eventDataSchema,
  media_data: mediaDataSchema.optional(),
})

export type MediaPauseEvent = z.infer<typeof MediaPauseSchema>
