import { useStore } from '@moonpig/web-core-stores'
import { useLoggedIn } from '@moonpig/web-core-auth'
import { DefaultSubjectAttributes } from '../../shared/DefaultSubjectAttributes'

export function useDefaultSubjectAttributes(): DefaultSubjectAttributes {
  const { store } = useStore()
  const { loggedIn } = useLoggedIn()

  return {
    storeId: store.id,
    loggedIn,
  }
}
