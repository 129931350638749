import { z } from 'zod'
import {
  customFontDataSchema,
  eventDataSchema,
  mediaDataSchema,
} from '../common.schema'

export const MediaEditSchema = z.object({
  event: z.literal('media_edit'),

  media_data: mediaDataSchema.optional(),
  event_data: eventDataSchema,
  custom_font_data: customFontDataSchema,
})

export type MediaEditEvent = z.infer<typeof MediaEditSchema>
