import { z } from 'zod'
import { eventDataSchema, zodBoolean, zodString } from '../../common.schema'

export const RemindersInteractionSchema = z.object({
  event: z.literal('reminders_interaction'),

  event_data: eventDataSchema,
  reminder_data: z.array(
    z.object({
      occasion: zodString.refine(
        val => val.toLowerCase() === val,
        val => ({
          message: 'value must be lowercase',
          params: { received: val },
        }),
      ),
      set: zodBoolean,
    }),
  ),
})

export type RemindersInteractionEvent = z.infer<
  typeof RemindersInteractionSchema
>
