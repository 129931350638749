import {
  IconSystemAvatar,
  IconSystemBriefcase,
  IconSystemCustomerService,
  IconSystemDelivery,
  IconSystemFaqs,
  IconSystemGroup,
  IconSystemOrders,
} from '@moonpig/launchpad-assets'
import { Region } from '@moonpig/web-core-types'
import { groupCardSearchTerms } from './groupCardsSearchTerms'
import { workSearchTerms } from './workSearchTerms'

export type FlatNavigationalSuggestion = {
  value: string
  url: string
  icon?: (props: React.SVGProps<SVGSVGElement>) => JSX.Element
}

type NavigationalSuggestionTarget = {
  type:
    | 'orders'
    | 'delivery'
    | 'account'
    | 'faqs'
    | 'customer-service'
    | 'group-cards'
    | 'work'
  url: (region: Region) => string
  icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element
  displayText: string
  group: 'CONTACT-US' | 'GROUP-CARDS' | 'WORK'
}

type NavigationalSuggestion = {
  triggerTerms: string[]
  target: NavigationalSuggestionTarget
  getApplicabilityForRegion: (region: Region) => boolean
}

type NavigationalSuggestionDictionary = {
  greetz: NavigationalSuggestion[]
  moonpig: NavigationalSuggestion[]
}

const getOrdersUrl = (region: Region) => `/${region}/account/orders/`
const getMyAccountUrl = (region: Region) => `/${region}/myaccount/`
const getMoonpigCustomerServicesUrl = (region: Region) =>
  `https://support-${region}.moonpig.com/hc/en-gb`
const getMoonpigDeliveryUrl = (region: Region) =>
  `/${region}/delivery-information/`
const getGreetzDeliveryUrl = (region: Region) => `/${region}/bezorginformatie/`
const getGreetzCustomerServiceUrl = (region: Region) =>
  `https://klantenservice.greetz.nl/hc/${region}`
const getGroupCardsUrl = (region: Region) =>
  region === 'us'
    ? `/${region}/group-cards/`
    : `/${region}/personalised-cards/group-cards/`
const getWorkUrl = (region: Region) => `/${region}/business/`

const applicableForAllRegions = (_: Region) => true
const applicableForUKOnly = (region: Region) => region === 'uk'

const navigationalSuggestions: NavigationalSuggestionDictionary = {
  moonpig: [
    {
      triggerTerms: [
        'order',
        'orders',
        'my order',
        'my orders',
        'track order',
        'order history',
      ],
      target: {
        type: 'orders',
        url: getOrdersUrl,
        icon: IconSystemOrders,
        displayText: 'Orders',
        group: 'CONTACT-US',
      },
      getApplicabilityForRegion: applicableForAllRegions,
    },
    {
      triggerTerms: [
        'postage',
        'delivery',
        'next day delivery',
        'same day delivery',
      ],
      target: {
        type: 'delivery',
        url: getMoonpigDeliveryUrl,
        icon: IconSystemDelivery,
        displayText: 'Delivery',
        group: 'CONTACT-US',
      },
      getApplicabilityForRegion: applicableForAllRegions,
    },
    {
      triggerTerms: [
        'address book',
        'unsubscribe',
        'wallet',
        'log out',
        'account',
      ],
      target: {
        type: 'account',
        url: getMyAccountUrl,
        icon: IconSystemAvatar,
        displayText: 'Account',
        group: 'CONTACT-US',
      },
      getApplicabilityForRegion: applicableForAllRegions,
    },
    {
      triggerTerms: [
        'faq',
        'faqs',
        'cancel order',
        'reorder',
        're-order',
        're order',
        'discount code',
        'voucher code',
        'order again',
        'delete account',
      ],
      target: {
        type: 'faqs',
        url: getMoonpigCustomerServicesUrl,
        icon: IconSystemFaqs,
        displayText: 'FAQs',
        group: 'CONTACT-US',
      },
      getApplicabilityForRegion: applicableForAllRegions,
    },
    {
      triggerTerms: [
        'contact',
        'contact us',
        'support',
        'customer services',
        'customer service',
        'live chat',
        'complaint',
      ],
      target: {
        type: 'customer-service',
        url: getMoonpigCustomerServicesUrl,
        icon: IconSystemCustomerService,
        displayText: 'Customer Service',
        group: 'CONTACT-US',
      },
      getApplicabilityForRegion: applicableForAllRegions,
    },
    {
      triggerTerms: groupCardSearchTerms,
      target: {
        type: 'group-cards',
        url: getGroupCardsUrl,
        icon: IconSystemGroup,
        displayText: 'Group Cards',
        group: 'GROUP-CARDS',
      },
      getApplicabilityForRegion: applicableForAllRegions,
    },
    {
      triggerTerms: workSearchTerms,
      target: {
        type: 'work',
        url: getWorkUrl,
        icon: IconSystemBriefcase,
        displayText: 'Moonpig for work - Employee cards',
        group: 'WORK',
      },
      getApplicabilityForRegion: applicableForUKOnly,
    },
  ],
  greetz: [
    {
      triggerTerms: [
        'bestelling',
        'bestellingen',
        'mijn bestellingen',
        'mijn bestelling',
        'orderhistorie',
      ],
      target: {
        type: 'orders',
        url: getOrdersUrl,
        icon: IconSystemOrders,
        displayText: 'Bestellingen',
        group: 'CONTACT-US',
      },
      getApplicabilityForRegion: applicableForAllRegions,
    },
    {
      triggerTerms: [
        'bezorginformatie',
        'bezorgkosten',
        'verzendkosten',
        'pakket',
        'vandaag bezorgd',
        'verzenden',
        'bezorgdatum',
        'levertijd',
      ],
      target: {
        type: 'delivery',
        url: getGreetzDeliveryUrl,
        icon: IconSystemDelivery,
        displayText: 'Bezorging',
        group: 'CONTACT-US',
      },
      getApplicabilityForRegion: applicableForAllRegions,
    },
    {
      triggerTerms: [
        'afmelden',
        'afmelden nieuwsbrief',
        'inloggen',
        'menu',
        'uitloggen',
        'nieuwsbrief',
        'aanmelden',
        'afmelden nieuwsbrief',
      ],
      target: {
        type: 'account',
        url: getMyAccountUrl,
        icon: IconSystemAvatar,
        displayText: 'Account',
        group: 'CONTACT-US',
      },
      getApplicabilityForRegion: applicableForAllRegions,
    },
    {
      triggerTerms: ['vragen'],
      target: {
        type: 'faqs',
        url: getGreetzCustomerServiceUrl,
        icon: IconSystemFaqs,
        displayText: 'FAQ',
        group: 'CONTACT-US',
      },
      getApplicabilityForRegion: applicableForAllRegions,
    },
    {
      triggerTerms: [
        'klantenservice',
        'factuur',
        'chat',
        'facturen',
        'buitenland',
        'saldocheck',
        'saldo check',
        'saldo',
        'klacht',
        'annuleren',
        'contact',
        'bestelling wijzigen',
        'bestelling annuleren',
        'meerdere adressen',
        'betalen',
        'account verwijderen',
        'credit',
        'credits',
        'chatten',
      ],
      target: {
        type: 'customer-service',
        url: getGreetzCustomerServiceUrl,
        icon: IconSystemCustomerService,
        displayText: 'Klantenservice',
        group: 'CONTACT-US',
      },
      getApplicabilityForRegion: applicableForAllRegions,
    },
  ],
}

export const getNavigationalSuggestions = (
  searchTerm: string,
  region: Region,
): NavigationalSuggestionTarget[] | undefined => {
  const site = region === 'nl' ? 'greetz' : 'moonpig'
  const suggestions = navigationalSuggestions[site]
  const matchIndex = suggestions.findIndex(
    suggestion =>
      suggestion.triggerTerms.includes(searchTerm?.trim().toLowerCase()) &&
      suggestion.getApplicabilityForRegion(region),
  )
  // returns an array containing the first matching suggestion,
  // followed by the rest of the suggestions, or undefined
  return matchIndex > -1
    ? [
        suggestions[matchIndex].target,
        ...suggestions.slice(0, matchIndex).map(s => s.target),
        ...suggestions.slice(matchIndex + 1).map(s => s.target),
      ]
    : undefined
}

export const getContactUsSuggestions = (
  searchTerm: string,
  region: Region,
): NavigationalSuggestionTarget[] | undefined => {
  const site = region === 'nl' ? 'greetz' : 'moonpig'
  const suggestions = navigationalSuggestions[site].filter(
    x => x.target.group === 'CONTACT-US',
  )
  const matchIndex = suggestions.findIndex(suggestion =>
    suggestion.triggerTerms.includes(searchTerm?.trim().toLowerCase()),
  )
  // returns an array containing the first matching suggestion,
  // followed by the rest of the suggestions, or undefined
  return matchIndex > -1
    ? [
        suggestions[matchIndex].target,
        ...suggestions.slice(0, matchIndex).map(s => s.target),
        ...suggestions.slice(matchIndex + 1).map(s => s.target),
      ]
    : undefined
}
