import React, { FC } from 'react'
import { Grid, Box, Flex } from '@moonpig/launchpad-components'
import { breakpoint, styled } from '@moonpig/launchpad-utils'
import { spacingPx } from '@moonpig/launchpad-theme'
import { system as s } from '@moonpig/launchpad-system'
import { HeaderWrapper } from '../HeaderWrapper'
import { MegaNav, MegaNavItemProps } from '../MegaNav'
import {
  UtilityMenu,
  UtilityMenuProps,
  UtilityNotifierProps,
} from '../UtilityMenu'
import {
  StoreSelectorProps,
  StoreSelector,
} from '../StoreSelector/StoreSelector'
import { CreditAmountProps } from '../CreditAmount/CreditAmount.experiment'
import { ExperimentCreditAmount } from '../CreditAmount'
import { HEADER_MENU_ICON_WIDTH_PX } from '../constants'

export type HeaderProps = {
  /**  Object containing the nav items */
  navItems: MegaNavItemProps[]
  utilityItems: UtilityMenuProps['items']
  /** Logo link */
  logoLink: React.ReactNode
  children?: React.ReactNode
  toggleAs?:
    | keyof JSX.IntrinsicElements
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    | React.ComponentType<React.PropsWithChildren<any>>
  //* * Text to render in the banner */
  bannerText?: string
  notifier?: UtilityNotifierProps
  /** Configuration options to set the store selector */
  storeSelectorConfig?: StoreSelectorProps
  experimentCreditAmountConfig?: CreditAmountProps
  nonStickyHeader?: boolean
}

const StyledHeaderWrapper = styled(HeaderWrapper)`
  isolation: isolate;
`

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  width: 100%;
  row-gap: ${spacingPx(4)};

  grid-template-areas:
    'logo storeLocator'
    'menu    utilities'
    'search     search';

  ${breakpoint('md')} {
    grid-template-areas:
      'menu   logo      utilities'
      'search search storeLocator'
      '.      .                 .';
  }

  ${breakpoint('xl')} {
    grid-template-areas:
      'logo search  utilities'
      'menu menu storeLocator'
      '.    .               .';
  }
`

const StyledLogo = styled(Box)`
  z-index: 6;
  position: relative;
  grid-area: logo;
  grid-column: 1 / span 8;

  ${breakpoint('md')} {
    margin-left: ${HEADER_MENU_ICON_WIDTH_PX};
    grid-column: 1 / span 14;

    ${s({ mt: 3 })}
  }

  ${breakpoint('xl')} {
    margin-left: 0;
    margin-top: 0;
    grid-column: 1 / span 4;
  }

  ${s({ pl: 6 })}
`

const StyledStoreSelector = styled(Box)<{ storeSelector: boolean }>`
  grid-area: storeLocator;
  grid-column: 9 / span 16;
  display: flex;
  justify-content: end;

  ${breakpoint('md')} {
    grid-column: 17 / span 8;
  }

  ${breakpoint('lg')} {
    grid-column: 19 / span 6;
  }

  ${breakpoint('xl')} {
    ${({ storeSelector }) =>
      storeSelector ? 'grid-column: 20 / span 5;' : 'grid-column: none'}
    align-items: end;
  }
`

const StyledMenu = styled(Box)<{ storeSelector: boolean }>`
  grid-area: menu;
  grid-column: 1 / span 6;

  ${breakpoint('md')} {
    grid-column: 1 / span 0;
  }

  ${breakpoint('xl')} {
    ${({ storeSelector }) =>
      storeSelector ? 'grid-column: 1 / span 19;' : 'grid-column: 1 / span 24;'}
  }
`

const StyledUtilities = styled(Box)`
  grid-area: utilities;
  display: flex;
  justify-content: end;
  grid-column: 7 / span 18;

  ${breakpoint('md')} {
    grid-column: 13 / span 12;
  }

  ${breakpoint('lg')} {
    grid-column: 16 / span 9;
  }

  ${breakpoint('xl')} {
    grid-column: 18 / span 7;
  }
`

const StyledSearch = styled(Box)<{ storeSelector: boolean }>`
  grid-area: search;
  grid-column: 1 / span 24;

  ${s({ px: 6 })}

  ${breakpoint('md')} {
    ${({ storeSelector }) =>
      storeSelector ? 'grid-column: 1 / span 16;' : 'grid-column: 1 / span 24;'}
  }

  ${breakpoint('lg')} {
    ${({ storeSelector }) =>
      storeSelector ? 'grid-column: 1 / span 18;' : 'grid-column: 1 / span 24;'}
  }

  ${breakpoint('xl')} {
    grid-column: 5 / span 13;
  }
`

export const Header: FC<React.PropsWithChildren<HeaderProps>> = ({
  logoLink,
  navItems,
  utilityItems,
  children,
  toggleAs,
  bannerText,
  notifier,
  storeSelectorConfig,
  experimentCreditAmountConfig,
  nonStickyHeader,
}) => {
  return (
    <StyledHeaderWrapper
      bannerText={bannerText}
      nonStickyHeader={nonStickyHeader}
    >
      <StyledGrid>
        <StyledLogo>{logoLink}</StyledLogo>
        <StyledSearch
          width="100%"
          my={{ xs: 4, xl: 2 }}
          storeSelector={!!storeSelectorConfig}
        >
          {children}
        </StyledSearch>
        <StyledUtilities>
          <UtilityMenu items={utilityItems} notifier={notifier} />
        </StyledUtilities>

        <StyledMenu storeSelector={!!storeSelectorConfig}>
          <MegaNav items={navItems} toggleAs={toggleAs} />
        </StyledMenu>

        <StyledStoreSelector storeSelector={!!storeSelectorConfig}>
          <Grid justifyContent="right">
            {storeSelectorConfig && (
              <Flex>
                <StoreSelector {...storeSelectorConfig} />
              </Flex>
            )}
            {experimentCreditAmountConfig && (
              <Box>
                <ExperimentCreditAmount {...experimentCreditAmountConfig} />
              </Box>
            )}
          </Grid>
        </StyledStoreSelector>
      </StyledGrid>
    </StyledHeaderWrapper>
  )
}
