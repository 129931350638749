import { z } from 'zod'
import { eventDataSchema, zodHyphenatedString } from '../../common.schema'

export const EditorExitSchema = z.object({
  event: z.literal('editor_exit'),

  event_data: eventDataSchema,
  product_data: z.object({
    design_id: zodHyphenatedString,
  }),
})

export type EditorExitEvent = z.infer<typeof EditorExitSchema>
