import React, { FC } from 'react'
import { DepartmentsEnum } from '@moonpig/web-core-types-graphql'
import { Box, Flex, SkeletonBox } from '@moonpig/launchpad-components'
import { styled } from '@moonpig/launchpad-utils'
import { Suggestion, SuggestionType } from './Suggestion'
import { SearchItemType, SuggestionItemType } from '../types'
import { EmptySuggestion } from './EmptySuggestion'
import { SKELETON_LIST_ITEM_HEIGHT_PX } from '../constants'

const StyledIconSkeletonBox = styled(SkeletonBox)`
  border-radius: 50%;
`

const StyledSkeletonBox = styled(SkeletonBox)`
  border-radius: 8px;
`

type SuggestionsProps = {
  suggestions: SuggestionType[]
  maxSuggestions: number
  value: string
  highlightedIndex: number | null
  department: { enum: DepartmentsEnum; name: string; title: string }
  getItemProps: (props: {
    itemIndex: number
    item: SuggestionItemType | SearchItemType
  }) => Record<string, unknown>
  getMenuProps: () => Record<string, unknown>
  startIndex?: number
  suggestionsLoading?: boolean
  departmentSuggestionsEnabled?: boolean
}

export const Suggestions: FC<SuggestionsProps> = ({
  suggestions,
  maxSuggestions,
  value,
  highlightedIndex,
  department,
  getItemProps,
  getMenuProps,
  startIndex = 0,
  suggestionsLoading,
}) => {
  const shouldShowSkeletonLoader = suggestionsLoading && value.length === 1

  const showEmptySuggestion = value.length > 0

  return (
    <ul data-testid={'lp-nav-search-suggestions'} {...getMenuProps()}>
      {shouldShowSkeletonLoader ? (
        <Box px={5} mb={6}>
          {Array.from({ length: maxSuggestions }).map((_, index) => (
            <Flex
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              my={6}
            >
              <StyledIconSkeletonBox
                width={SKELETON_LIST_ITEM_HEIGHT_PX}
                height={SKELETON_LIST_ITEM_HEIGHT_PX}
                mr={4}
              />
              <StyledSkeletonBox
                width={`calc(${
                  20 + Math.random() * (100 - 40)
                }% - ${SKELETON_LIST_ITEM_HEIGHT_PX})`}
                height={SKELETON_LIST_ITEM_HEIGHT_PX}
              />
            </Flex>
          ))}
        </Box>
      ) : (
        <>
          <>
            {suggestions.slice(0, maxSuggestions).map((suggestion, index) => (
              <Suggestion
                getItemProps={getItemProps}
                item={suggestion}
                itemIndex={startIndex + index}
                value={value}
                highlighted={highlightedIndex === startIndex + index}
                key={
                  /* istanbul ignore next */
                  suggestion.department
                    ? `${suggestion.value}-${suggestion.department.title}`
                    : suggestion.value
                }
              />
            ))}

            {showEmptySuggestion && (
              <EmptySuggestion
                searchTerm={value}
                department={department}
                getItemProps={getItemProps}
                highlighted={
                  highlightedIndex ===
                  startIndex + suggestions.slice(0, maxSuggestions).length
                }
              />
            )}
          </>
        </>
      )}
    </ul>
  )
}
