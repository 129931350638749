import { z } from 'zod'
import { eventDataSchema, mediaDataSchema } from '../../common.schema'

export const MediaProgressSchema = z.object({
  event: z.literal('media_progress'),

  event_data: eventDataSchema,
  media_data: mediaDataSchema.optional(),
})

export type MediaProgressEvent = z.infer<typeof MediaProgressSchema>
