import { z } from 'zod'
import {
  ecommerceItemSchema,
  ecommerceSchema,
  eventDataSchema,
  zodLowerCaseString,
} from '../common.schema'

export const ViewPromotionSchema = z.object({
  event: z.literal('view_promotion'),

  ecommerce: ecommerceSchema.partial().extend({
    items: z.array(
      ecommerceItemSchema.extend({
        creative_name: zodLowerCaseString.optional(),
      }),
    ),
    creative_name: zodLowerCaseString.optional(),
  }),
  event_data: eventDataSchema,
})

export type ViewPromotionEvent = z.infer<typeof ViewPromotionSchema>
