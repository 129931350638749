import React, { useEffect, useState, useCallback } from 'react'
import { css, styled } from '@moonpig/launchpad-utils'
import { IconSystemReminder } from '@moonpig/launchpad-assets'
import { Box, Flex, SideModal } from '@moonpig/launchpad-components'
import { useRouter } from '@moonpig/web-core-routing'
import { system as s } from '@moonpig/launchpad-system'
import { useLocaleText } from '../locale'

const ICON_SIZE_PX = '28px'
const BADGE_SIZE_PX = '10px'

const defaultColours = {
  default: 'colorInteractionIcon',
  hover: 'colorInteractionIconHover',
  focus: 'colorInteractionSelectedState',
  active: 'colorInteractionSelectedState',
}

const notificationBadgeStyle = css`
  &::after {
    content: '';
    width: ${BADGE_SIZE_PX};
    height: ${BADGE_SIZE_PX};
    display: inline-block;
    ${s({
      bgcolor: 'colorFeedbackError',
      borderRadius: 2,
      position: 'absolute',
      margin: '0 0 16px 16px',
    })}
  }
`

const StyledIcon = styled(Box)<{
  showNotificationBadge: boolean
}>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ showNotificationBadge }) =>
    showNotificationBadge && notificationBadgeStyle}
`

const StyledButton = styled.button`
  ${s({
    color: defaultColours.default,
  })}

  &:not(:disabled) {
    &:hover {
      ${s({
        color: defaultColours.hover,
      })}
    }

    &:active {
      ${s({
        color: defaultColours.active,
      })}
    }

    &:focus {
      ${s({
        color: defaultColours.focus,
      })}

      &:hover {
        ${s({
          color: defaultColours.hover,
        })}
      }
    }
  }
`

export type NotifierProps = {
  notifications: React.ReactNode[]
  onClick: () => void
  onDismiss: (trackEvent: boolean) => void
  showNotificationBadge: boolean
}

export const Notifier: React.FC<React.PropsWithChildren<NotifierProps>> = ({
  notifications,
  onClick,
  onDismiss,
  showNotificationBadge,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const handleClick = useCallback(() => {
    onClick()
    setModalIsOpen(true)
  }, [onClick])

  const testId = 'lp-nav-notifications'
  const t = useLocaleText()
  const label = t('notifier.reminders')
  const router = useRouter()
  const triggerRef = React.useRef<HTMLButtonElement | null>(null)

  useEffect(() => {
    /* istanbul ignore next */
    router.onRouteChangeComplete(() => {
      onDismiss(false)
      setModalIsOpen(false)
    })
    return () => {
      router.onRouteChangeComplete(/* istanbul ignore next */ () => {})
    }
  }, [router, onDismiss])

  return (
    <>
      <Flex flexDirection="column" alignItems="center">
        <StyledButton ref={triggerRef} type="button" onClick={handleClick}>
          <Flex flexDirection="column" alignItems="center">
            <StyledIcon
              showNotificationBadge={showNotificationBadge}
              data-testid={`${testId}-icon`}
            >
              <IconSystemReminder width={ICON_SIZE_PX} height={ICON_SIZE_PX} />
            </StyledIcon>
            <span className="text">{label}</span>
          </Flex>
        </StyledButton>
      </Flex>

      <SideModal
        isOpen={modalIsOpen}
        onDismiss={() => {
          onDismiss(true)
          setModalIsOpen(false)
        }}
        heading={t('notifier.notifications')}
        description={t('notifier.notifications_modal')}
        triggerRef={triggerRef}
      >
        <Box>
          {notifications.length > 0 ? (
            notifications.map((notification, index) => {
              const key = `notification-${index}`
              return (
                <Box mb={4} key={key}>
                  {notification}
                </Box>
              )
            })
          ) : (
            <p>{t('notifier.no_notifications')}</p>
          )}
        </Box>
      </SideModal>
    </>
  )
}
