import React, { FC } from 'react'
import { system as s } from '@moonpig/launchpad-system'
import { breakpoint, styled } from '@moonpig/launchpad-utils'
import { Text, Flex } from '@moonpig/launchpad-components'
import { IconSystemCross, IconSystemClock } from '@moonpig/launchpad-assets'
import { useCoreLocaleText } from '@moonpig/web-core-locale-text'
import { RecentSearchItemType, RecentSearchType } from '../types'
import { useLocaleText } from '../../locale'
import {
  CLEAR_BUTTON_SIZE_DESKTOP_PX,
  CLEAR_BUTTON_SIZE_MOBILE_PX,
  LIST_ICON_SIZE_PX,
} from '../constants'

const StyledRecentSearch = styled.li`
  cursor: pointer;
  ${s({ px: 3, color: 'colorTextLabel' })}

  &:hover, &:focus {
    ${s({ bgcolor: 'colorBackground02', borderRadius: '12px' })}
  }

  &:active {
    ${s({ bgcolor: 'colorBlack20' })}
  }

  ${breakpoint('md')} {
    ${s({ p: 3 })}
    button {
      display: none;
    }
    &:hover,
    &:focus {
      button {
        display: flex;
      }
    }
  }
`

const StyledRecentSearchText = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`

const StyledRecentSearchSecondaryText = styled(Text)`
  white-space: nowrap;
  width: 20%;
  ${s({ color: 'colorBlack60' })}
`

const StyledClearButton = styled.button`
  width: ${CLEAR_BUTTON_SIZE_MOBILE_PX};
  height: ${CLEAR_BUTTON_SIZE_MOBILE_PX};
  align-items: center;
  justify-content: center;

  ${s({ color: 'colorInteractionControl', borderRadius: '8px' })}

  ${breakpoint('md')} {
    width: ${CLEAR_BUTTON_SIZE_DESKTOP_PX};
    height: ${CLEAR_BUTTON_SIZE_DESKTOP_PX};
  }

  &:hover,
  &:focus {
    ${s({ bgcolor: 'colorBackground01', color: 'colorInteractionIconHover' })}
  }
`

const StyledIconWrapper = styled(Flex)`
  ${s({
    bgcolor: 'colorBackground01',
    borderRadius: '8px',
    p: 4,
    mr: 4,
    color: 'colorInteractionControl',
  })}
`

type RecentSearchProps = {
  item: RecentSearchType
  itemIndex: number
  getItemProps: (props: {
    itemIndex: number
    item: RecentSearchItemType
  }) => Record<string, unknown>
  onRemoveItem: (item: RecentSearchType) => void
} & React.HTMLProps<HTMLElement>

export const RecentSearch: FC<RecentSearchProps> = ({
  item,
  itemIndex,
  getItemProps,
  onRemoveItem,
}) => {
  const t = useLocaleText()
  const coreLocale = useCoreLocaleText()
  const capitalizedSearchTerm = `${item.searchTerm[0].toUpperCase()}${item.searchTerm?.slice(
    1,
  )}`

  const isCards = (dept: string) => {
    return dept === 'all_cards'
  }

  const isFlowers = (dept: string) => {
    return dept === 'all_flowers_and_plants'
  }

  const isGifts = (dept: string) => {
    return dept === 'all_gifts'
  }

  const getDepartmentText = (dept: string) => {
    if (isCards(dept)) {
      return coreLocale('common.cards')
    }
    if (isFlowers(dept)) {
      return coreLocale('common.flowers')
    }
    if (isGifts(dept)) {
      return coreLocale('common.gifts')
    }
    /* istanbul ignore next */
    return dept
  }

  return (
    <StyledRecentSearch
      tabIndex={0}
      aria-label={`${t('search.search_for')} ${capitalizedSearchTerm}`}
      {...getItemProps({
        itemIndex,
        item: { ...item, itemType: 'recent' },
      })}
      data-search-term={item.searchTerm}
      data-item-type="recent"
      data-parent-department={item.department}
    >
      <Flex justifyContent="space-between" alignItems="center">
        <Flex alignItems="center">
          <StyledIconWrapper>
            <IconSystemClock
              width={LIST_ICON_SIZE_PX}
              height={LIST_ICON_SIZE_PX}
            />
          </StyledIconWrapper>
          <StyledRecentSearchText>
            {capitalizedSearchTerm}
            <StyledRecentSearchSecondaryText>
              &nbsp;{getDepartmentText(item.department)}
            </StyledRecentSearchSecondaryText>
          </StyledRecentSearchText>
        </Flex>
        <StyledClearButton
          aria-label={`${t(
            'search.clear_recent_search',
          )} ${capitalizedSearchTerm}`}
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
            onRemoveItem(item)
          }}
          data-testid="lp-nav-search-recent-remove-item"
        >
          <IconSystemCross
            width={LIST_ICON_SIZE_PX}
            height={LIST_ICON_SIZE_PX}
            aria-hidden
          />
        </StyledClearButton>
      </Flex>
    </StyledRecentSearch>
  )
}
