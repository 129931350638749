import React, { FC } from 'react'
import { getParentDepartment } from '@moonpig/web-core-utils'
import { RecentSearch } from './RecentSearch'
import { RecentSearchItemType, RecentSearchType } from '../types'

type RecentSearchesProps = {
  recentSearches: RecentSearchType[]
  getItemProps: (props: {
    itemIndex: number
    item: RecentSearchItemType
  }) => Record<string, unknown>
  getMenuProps: () => Record<string, unknown>
  onRemoveItem: (item: RecentSearchType) => void
  department: string
}

export const RecentSearches: FC<RecentSearchesProps> = ({
  recentSearches,
  getItemProps,
  getMenuProps,
  onRemoveItem,
  department,
}) => {
  const parentDepartment = getParentDepartment(department)
  return (
    <ul {...getMenuProps()} data-testid={'lp-nav-search-recent-searches'}>
      {recentSearches
        .filter(
          x =>
            x.department.toLowerCase() ===
            parentDepartment.toString().toLowerCase(),
        )
        .map((item, index) => {
          return (
            <RecentSearch
              key={`${item.department}-${item.searchTerm}`}
              item={item}
              itemIndex={index}
              getItemProps={getItemProps}
              onRemoveItem={onRemoveItem}
            />
          )
        })}
    </ul>
  )
}
