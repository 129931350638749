import React, { FC, useEffect } from 'react'
import { useForm, SubmitHandler, Controller, useWatch } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  Checkbox,
  Dropdown,
  FormControlLabel,
  HelperText,
  TextInput,
} from '@moonpig/launchpad-forms'
import { Form, Markup } from '@moonpig/web-shared-components'
import { system as s } from '@moonpig/launchpad-system'
import { styled } from '@moonpig/launchpad-utils'
import {
  Alert,
  Box,
  LoadingIndicator,
  PrimaryButton,
  Text,
} from '@moonpig/launchpad-components'
import { Occasion } from '@moonpig/web-shared-types-graphql/graphqlTypes'
import { useCoreLocaleText } from '@moonpig/web-core-locale-text'
import { IconStaticReminderChristmas } from '@moonpig/launchpad-assets'
import { theme } from '@moonpig/launchpad-theme'
import { useLocaleText } from '../../localisation'
import { getDays, formattedMonths, defaultSelectValue } from './utils'
import { useSupportedReminderFacets } from '../../hooks'
import { ReminderInput, reminderInputSchema } from '../../types'

const StyledLoadingIndicator = styled(LoadingIndicator)`
  width: 32px;
  height: 32px;
  position: absolute;
  bottom: 44px;
  left: 8px;
`

const StyledDropdown = styled(Dropdown)`
  ${s({ flex: 1 })}
`

const DEFAULT_FORM_VALUES = {
  name: '',
  occasion: '' as Occasion,
  occasionDate: {
    day: 0,
    month: 0,
  },
}

const StyledReminderChristmasBox = styled(Box)`
  ${s({ bgcolor: 'colorBackground03', borderRadius: 2, py: 4, mt: 5 })}
  display: flex;
  justify-content: space-between;
  align-items: center;
  label {
    height: auto;
    width: 100%;
  }
`

type ReminderFormProps = {
  onSubmit: (formValues: ReminderInput) => void
  defaultValues?: ReminderInput
  onChange?: (data: ReminderInput) => void
  ctaText?: string
  disableCTA?: boolean
  triggerValidation?: boolean
  resetAfterSubmit?: boolean
  showRelationshipField?: boolean
  showChristmasCheckbox?: boolean
}

export const ReminderForm: FC<ReminderFormProps> = ({
  onSubmit,
  onChange,
  ctaText,
  disableCTA,
  defaultValues = DEFAULT_FORM_VALUES,
  resetAfterSubmit = false,
  triggerValidation = false,
  showRelationshipField = false,
  showChristmasCheckbox = false,
}) => {
  const coreLocaliseText = useCoreLocaleText()
  const localiseText = useLocaleText()

  const {
    occasions,
    relationships,
    error: reminderFormDataError,
    loading: reminderFormDataLoading,
  } = useSupportedReminderFacets()

  const {
    handleSubmit,
    reset,
    control,
    getValues,
    setValue,
    trigger,
    formState: { errors, isValid: isFormValid },
  } = useForm<ReminderInput>({
    mode: 'onChange',
    defaultValues,
    resolver: zodResolver(reminderInputSchema),
  })

  const currentMonth = useWatch({ control, name: 'occasionDate.month' })
  const recipientName = useWatch({ control, name: 'name' })

  const days = getDays(currentMonth)
  const months = formattedMonths(coreLocaliseText)

  const onFormSubmit: SubmitHandler<ReminderInput> = data => {
    onSubmit(data)
    if (resetAfterSubmit) {
      reset()
    }
  }

  useEffect(() => {
    if (triggerValidation) {
      trigger()
    }
  }, [trigger, triggerValidation])

  return (
    <>
      {reminderFormDataError && (
        <Alert variant="error" my={5}>
          {localiseText('common.reminder_form.error')}
        </Alert>
      )}
      <Form
        data-testid="reminders-form"
        method="post"
        onSubmit={handleSubmit(onFormSubmit)}
        onChange={() => onChange?.(getValues())}
      >
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <TextInput
              {...field}
              label={localiseText('common.reminder_form.their_name')}
              placeholder={localiseText(
                'common.reminder_form.name_placeholder',
              )}
              mb={5}
              error={!!errors.name}
              helperText={
                errors.name && localiseText('common.reminder_form.name_error')
              }
            />
          )}
        />
        {showRelationshipField && (
          <Box position="relative">
            <Controller
              name="relationship"
              control={control}
              render={({ field }) => (
                <Dropdown
                  {...field}
                  label={`${localiseText(
                    'common.reminder_form.they_are_my',
                  )} ${localiseText('common.reminder_form.optional')}`}
                  options={[defaultSelectValue, ...relationships]}
                  mb={5}
                  value={field.value || ''}
                  onChange={event => {
                    if (event.target.value === '') {
                      setValue('relationship', null)
                    }
                    field.onChange(event)
                  }}
                  error={!!errors.relationship}
                  helperText={
                    errors.relationship &&
                    localiseText('common.reminder_form.relationship_error')
                  }
                  disabled={reminderFormDataLoading}
                />
              )}
            />
            {reminderFormDataLoading && (
              <StyledLoadingIndicator
                label={localiseText(
                  'common.reminder_form.loading_relationships',
                )}
              />
            )}
          </Box>
        )}
        <Box position="relative">
          <Controller
            name="occasion"
            control={control}
            render={({ field }) => (
              <Dropdown
                {...field}
                label={`${localiseText('common.reminder_form.occasion')}`}
                options={[defaultSelectValue, ...occasions]}
                mb={5}
                value={field.value || ''}
                error={!!errors.occasion}
                helperText={
                  errors.occasion &&
                  localiseText('common.reminder_form.occasion_error')
                }
                onChange={event => {
                  const defaultDate = occasions.find(
                    occ => occ.value === event.target.value,
                  )?.defaultDate
                  if (defaultDate) {
                    setValue('occasionDate.day', defaultDate.day)
                    setValue('occasionDate.month', defaultDate.month)
                  }
                  field.onChange(event)
                }}
                disabled={reminderFormDataLoading}
              />
            )}
          />
          {reminderFormDataLoading && (
            <StyledLoadingIndicator
              label={localiseText('common.reminder_form.loading_occasions')}
            />
          )}
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Controller
            name="occasionDate.day"
            control={control}
            render={({ field }) => (
              <StyledDropdown
                {...field}
                value={field.value.toString() || ''}
                label={localiseText('common.reminder_form.day')}
                options={days}
                mb={4}
                mr={4}
                error={!!errors.occasionDate?.day}
              />
            )}
          />
          <Controller
            name="occasionDate.month"
            control={control}
            render={({ field }) => (
              <StyledDropdown
                {...field}
                value={field.value.toString() || ''}
                label={localiseText('common.reminder_form.month')}
                options={months}
                mb={4}
                error={!!errors.occasionDate?.month}
              />
            )}
          />
        </Box>
        <Text
          color="colorBlack40"
          typography="typeBodyCaption"
          fontWeight={700}
        >
          {localiseText('common.reminder_form.guess')}
        </Text>
        {errors.occasionDate && (
          <HelperText error id={'date-error-text'}>
            {localiseText('common.reminder_form.date_error')}
          </HelperText>
        )}
        {showChristmasCheckbox && (
          <StyledReminderChristmasBox
            data-testid={
              recipientName
                ? 'christmas-reminder-with-name'
                : 'christmas-reminder'
            }
          >
            <Box ml={6} mr={6}>
              <IconStaticReminderChristmas height="38px" width="40px" />
            </Box>
            <Controller
              name="christmasReminder"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  mr={4}
                  name="christmas-reminder"
                  aria-label="christmas-reminder"
                  label={
                    <Text width={'100%'} whiteSpace="normal">
                      <Markup
                        htmlString={localiseText(
                          'common.reminder_form.christmas_reminder_prompt',
                          {
                            name: recipientName,
                            emphasisedTextColour:
                              theme.colors.colorFeedbackInformation,
                          },
                        )}
                        as="span"
                      />
                    </Text>
                  }
                  id="christmas-reminder"
                  labelPosition="left-justified"
                >
                  <Checkbox
                    {...field}
                    checked={Boolean(field.value)}
                    value={'christmas-reminder'}
                  />
                </FormControlLabel>
              )}
            />
          </StyledReminderChristmasBox>
        )}
        {ctaText && (
          <PrimaryButton
            loading={reminderFormDataLoading}
            disabled={!isFormValid || disableCTA}
            mt={8}
            type="submit"
            width="100%"
          >
            {ctaText}
          </PrimaryButton>
        )}
      </Form>
    </>
  )
}
