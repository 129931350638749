import { z } from 'zod'
import { eventDataSchema, zodString } from '../common.schema'

export const AbtestSchema = z.object({
  event: z.literal('abtest'),

  event_data: eventDataSchema.extend({
    action: zodString.regex(/^[a-z0-9_|/-]+$/, {
      message:
        'Value must be lowercase. Only `_|/-` are allowed as special characters',
    }),
  }),
})

export type AbtestEvent = z.infer<typeof AbtestSchema>
