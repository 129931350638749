import { z } from 'zod'
import {
  errorDataSchema,
  eventDataSchema,
  userDataSchema,
  zodLoginMethodEnum,
} from '../../common.schema'

export const SignUpErrorSchema = z.object({
  event: z.literal('sign_up_error'),

  user_data: userDataSchema.extend({
    login_method: zodLoginMethodEnum,
  }),
  event_data: eventDataSchema,
  error_data: errorDataSchema,
})

export type SignUpErrorEvent = z.infer<typeof SignUpErrorSchema>
