import React from 'react'
import { Region } from '@moonpig/web-core-types'
import { NavItem } from './data/trackedNavItems'
import { Header } from './Header'
import { Footer } from '../Footer'
import { ErrorBoundary } from '../Error'
import { HideSearchBarOption } from './types'
import { GoogleOneTap } from '../GoogleOneTap'

type FooterProps = React.ComponentProps<typeof Footer>

export const DefaultLayout: React.FC<
  React.PropsWithChildren<{
    searchDepartment?: {
      name: string
      title: string
    }
    searchTerm?: string
    navItems: NavItem[]
    footer: FooterProps['linkListItems']
    region: Region
    shouldHideFooter?: boolean
    hideSearchBar?: HideSearchBarOption
    shouldHideGoogleOneTap?: boolean
    headerLogoIconUrl?: string
    nonStickyHeader?: boolean
  }>
> = ({
  children,
  searchDepartment,
  searchTerm,
  navItems,
  footer,
  region,
  shouldHideFooter,
  hideSearchBar,
  shouldHideGoogleOneTap,
  headerLogoIconUrl,
  nonStickyHeader,
}) => {
  return (
    <>
      {!shouldHideGoogleOneTap && <GoogleOneTap />}
      <Header
        region={region}
        navItems={navItems}
        searchDepartment={searchDepartment}
        searchTerm={searchTerm}
        headerLogoIconUrl={headerLogoIconUrl}
        hideSearchBar={hideSearchBar}
        nonStickyHeader={nonStickyHeader}
      />
      <ErrorBoundary>{children}</ErrorBoundary>
      {!shouldHideFooter && <Footer region={region} linkListItems={footer} />}
    </>
  )
}
