import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { Header as LaunchpadHeader } from '@moonpig/web-core-nav'
import {
  createGenericEvent,
  createGenericSelectContentEvent,
  createNavigationEvent,
  trackGAEvent,
} from '@moonpig/web-core-analytics'
import { createLinkHref } from '@moonpig/web-core-link'
import { Region } from '@moonpig/web-core-types'
import { getBrowserCookie, setBrowserCookie } from '@moonpig/web-core-cookies'
import { useExperiment } from '@moonpig/web-core-experiments'
import { LogoLink } from '@moonpig/web-core-brand/components'
import { useNotificationsContext } from '@moonpig/web-core-context'
import { SearchBar } from '@moonpig/web-core-app-shell-search'
import { useLoggedIn } from '@moonpig/web-core-auth'
import { useLocaleText } from '../../AppShell.locale'
import { buildTrackedNavItems, NavItem } from './data/trackedNavItems'
import { TrackedToggle } from './data/trackedToggle'
import { useStoreSelectorConfig } from './useStoreSelectorConfig'
import { createUtilityItems } from './createUtilityItems'
import { useBasketTotalItems } from './useBasketItemTotals'
import { useFetchReminders } from './reminderNotification/useFetchReminders'
import {
  saveInLocalStorage,
  shouldShowNotificationBadgeForReminders,
} from './reminderNotification/shouldShowNotificationBadgeForReminders'
import { useCreditAmountConfig } from './credit/useCreditAmountConfig'
import { useIsMobile } from '../../hooks/useIsMobile'
import { HideSearchBarOption } from './types'

const EXTRA_LARGE_BREAKPOINT = 1200

const buildHomeLink = (region: Region) => {
  const options = {
    url: `/${region}/`,
    trackEvent: createNavigationEvent({
      action: 'logos',
      label: 'Moonpig Logo',
    }),
  }

  const result = {
    href: createLinkHref(options),
  }
  return result
}

const trackTooltipImpression = (text: string) => {
  const formattedText = text
    .replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, '')
    .toLocaleLowerCase()
    .trim()

  trackGAEvent({
    event: 'impression',
    content_data: {
      content_type: `navigation | view account login tooltip | header tooltip | ${formattedText}`,
      item_id: undefined,
    },

    event_data: {
      category: 'navigation',
      action: 'view account login tooltip',
      label: `header tooltip | ${formattedText}`,
      non_interaction: true,
      value: undefined,
    },
    product_data: undefined,
  })
}

const shouldHideSearchBar = (
  isMobile: boolean,
  hideOption?: HideSearchBarOption,
) => {
  switch (hideOption) {
    case 'all':
      return true
    case 'mobile':
      return isMobile
    case 'desktop':
      return !isMobile
    default:
      return false
  }
}

export const Header: React.FC<{
  region: Region
  navItems: NavItem[]
  searchDepartment?: {
    name: string
    title: string
  }
  searchTerm?: string
  headerLogoIconUrl?: string
  hideSearchBar?: HideSearchBarOption
  nonStickyHeader?: boolean
}> = ({
  region,
  navItems,
  searchDepartment,
  searchTerm,
  hideSearchBar,
  headerLogoIconUrl,
  nonStickyHeader,
}) => {
  const t = useLocaleText()
  const reminders = useFetchReminders()
  const creditConfig = useCreditAmountConfig({ region })
  const { loggedIn } = useLoggedIn()
  const [accountTooltip, setAccountTooltip] = React.useState('')
  const isMobile = useIsMobile()

  const showGiftVouchers = useExperiment('show-gift-vouchers') === 'enabled'
  const isSearchBarHidden = useMemo(
    () => shouldHideSearchBar(isMobile, hideSearchBar),
    [hideSearchBar, isMobile],
  )

  const showLoginTooltip =
    useExperiment('browse-login-tooltip', 'control')?.toLowerCase() ===
    'variant'

  const filteredGiftVoucherNavItems = showGiftVouchers
    ? navItems
    : navItems.map(navItem => {
        const newDropdown = navItem.items.map(subSubItem => {
          const dropdownItems = subSubItem.items.filter(
            dropdownItem => dropdownItem.title !== 'Gift Cards',
          )
          return { ...subSubItem, items: dropdownItems }
        })

        return { ...navItem, items: newDropdown }
      })

  const [openedNotificationsOnce, setOpenedNotificationsOnce] = useState<
    boolean | null
  >(null)
  useEffect(() => {
    const openedOnce =
      getBrowserCookie('mnpg_show_notification_badge') === 'false'
    setOpenedNotificationsOnce(openedOnce)
  }, [openedNotificationsOnce])

  const showNotificationBadge =
    shouldShowNotificationBadgeForReminders(reminders) ||
    openedNotificationsOnce === false

  const notifications = useNotificationsContext()

  const onClick = useCallback(() => {
    trackGAEvent(
      createGenericEvent({
        eventAction: 'icon',
        eventLabel: 'Reminders',
        eventCategory: 'navigation',
      }),
    )
    setBrowserCookie('mnpg_show_notification_badge', 'false', {
      path: '/',
      maxAge: 2592000,
    })
    setOpenedNotificationsOnce(true)
    saveInLocalStorage(reminders)
  }, [reminders])

  const onDismiss = useCallback<(trackEvent: boolean) => void>(trackEvent => {
    if (trackEvent) {
      return trackGAEvent(
        createGenericSelectContentEvent({
          eventCategory: 'reminders',
          eventAction: 'toolbar close',
          eventLabel: 'reminder toolbar',
        }),
      )
    }
  }, [])

  const notifier = {
    notifications: notifications.notifications,
    onClick,
    onDismiss,
    showNotificationBadge,
  }

  const storeSelectorConfig = useStoreSelectorConfig()

  const utilityItems = createUtilityItems({
    region,
    localisedLabels: {
      basket: t('basket'),
      account: t('account'),
      accountTooltip,
    },
    basketTotalItems: useBasketTotalItems().basketTotalItems,
  })

  useEffect(() => {
    const checkAfterDelay = setTimeout(() => {
      const isHomepage = window.location.pathname === `/${region}/`

      if (showLoginTooltip && isHomepage && !loggedIn && loggedIn !== null) {
        const text = t('account_tooltip')
        setAccountTooltip(text)
        const isTooltipInView =
          (window.scrollY === 0 &&
            window.innerWidth < EXTRA_LARGE_BREAKPOINT) ||
          window.innerWidth >= EXTRA_LARGE_BREAKPOINT

        if (isTooltipInView) {
          trackTooltipImpression(text)
        }
      }
    }, 800)

    return () => clearTimeout(checkAfterDelay)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn])

  return (
    <LaunchpadHeader
      navItems={buildTrackedNavItems(filteredGiftVoucherNavItems)}
      logoLink={
        <LogoLink
          href={buildHomeLink(region).href}
          title={t('logo_link')}
          complementaryImageUrl={headerLogoIconUrl}
        />
      }
      utilityItems={utilityItems}
      toggleAs={TrackedToggle}
      notifier={notifier}
      storeSelectorConfig={storeSelectorConfig}
      experimentCreditAmountConfig={creditConfig}
      nonStickyHeader={nonStickyHeader}
    >
      {!isSearchBarHidden && (
        <SearchBar
          searchDepartment={searchDepartment}
          searchTerm={searchTerm}
          key={searchTerm}
        />
      )}
    </LaunchpadHeader>
  )
}
