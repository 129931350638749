import { z } from 'zod'
import {
  eventDataSchema,
  filterDataSchema,
  resultsDataSchema,
  zodResultsDataInputTypeEnum,
} from '../common.schema'

export const ViewGalleryResultsSchema = z.object({
  event: z.literal('view_gallery_results'),

  event_data: eventDataSchema,
  results_data: resultsDataSchema.extend({
    input_type: zodResultsDataInputTypeEnum.or(
      z.enum([
        'nba',
        'toggle',
        'quick filters',
        'filters menu',
        'filter',
        'gallery',
      ]),
    ),
  }),
  filter_data: filterDataSchema,
})

export type ViewGalleryResultsEvent = z.infer<typeof ViewGalleryResultsSchema>
