import React, { FC, useContext, useMemo } from 'react'
import type { Store } from '@moonpig/web-core-types'
import { STORES } from '@moonpig/web-core-brand/config'

const StoreContext = React.createContext<{
  storeId: string
  stores: Store[]
}>({
  storeId: STORES[0].id,
  stores: STORES,
})

export const StoreProvider: FC<
  React.PropsWithChildren<{ storeId?: string; stores?: Store[] }>
> = ({ storeId, stores = STORES, children }) => {
  const storeIdValue = storeId || stores[0].id

  const value = useMemo(
    () => ({ storeId: storeIdValue, stores }),
    [storeIdValue, stores],
  )

  return <StoreContext.Provider value={value}>{children}</StoreContext.Provider>
}

type UseStoreResult = {
  store: Store
  defaultStore: Store
  stores: Store[]
  storeIds: string[]
  storesById: { [id: string]: Store }
  getStoreById: (id: string) => Store
  hasStore: (id: string) => boolean
}

const byId = <T extends { id: string }>(
  v: T[],
): {
  [id: string]: T
} =>
  v.reduce<{
    [id: string]: T
  }>((acc, curr) => {
    acc[curr.id] = curr
    return acc
  }, {})

export const createStores = (stores: Store[] = STORES) => {
  const storesById = byId(stores)

  const hasStore = (id: string) => Boolean(storesById[id])

  const getStoreById = (id: string | null) => {
    if (id === null || !storesById[id]) {
      return stores[0]
    }

    return storesById[id]
  }

  const storeIds = stores.map(s => s.id)

  const defaultStore = stores[0]

  return {
    defaultStore,
    stores,
    storeIds,
    storesById,
    getStoreById,
    hasStore,
  }
}

export const useStore = (): UseStoreResult => {
  const context = useContext(StoreContext)

  const { stores } = context

  const results = useMemo(() => createStores(stores), [stores])

  const store = results.storesById[context.storeId]

  return {
    ...results,
    store,
  }
}

export const useStoreId = () => {
  const { store } = useStore()
  return store.id
}

export const useLocale = () => {
  const { store } = useStore()
  return store.locale
}

export const useLanguage = () => {
  const { store } = useStore()
  return store.locale.language
}

export const useAnalytics = () => {
  const { store } = useStore()
  return store.analytics
}
