import React, { FC } from 'react'
import { STORES as STORES_MOONPIG } from '@moonpig/web-core-brand/config/moonpig'
import { STORES as STORES_GREETZ } from '@moonpig/web-core-brand/config/greetz'
import type { Store } from '@moonpig/web-core-types'
import { StoreProvider } from './stores'

const getStores = ({
  stores,
  brand,
  storeId,
}: {
  stores?: Store[]
  brand?: string
  storeId?: string
}) => {
  if (stores) {
    return stores
  }

  if (brand === 'greetz') {
    return STORES_GREETZ
  }

  if (storeId === 'nl') {
    return STORES_GREETZ
  }

  return STORES_MOONPIG
}

export const StoreProviderBrand: FC<
  React.PropsWithChildren<{
    storeId?: string
    stores?: Store[]
    brand?: string
  }>
> = ({ children, storeId, stores, brand }) => {
  return (
    <StoreProvider
      storeId={storeId}
      stores={getStores({ stores, brand, storeId })}
    >
      {children}
    </StoreProvider>
  )
}
