import { z } from 'zod'
import {
  basketDataSchema,
  discountDataSchema,
  ecommerceItemSchema,
  ecommerceSchema,
  eventDataSchema,
  zodNumber,
} from '../../common.schema'

export const BeginCheckoutSchema = z.object({
  event: z.literal('begin_checkout'),

  basket_data: basketDataSchema.extend({
    size: zodNumber,
  }),
  ecommerce: ecommerceSchema.extend({
    items: z.array(ecommerceItemSchema),
  }),
  event_data: eventDataSchema,
  discount_data: discountDataSchema.optional(),
})

export type BeginCheckoutEvent = z.infer<typeof BeginCheckoutSchema>
