import { z } from 'zod'
import { errorDataSchema, eventDataSchema } from '../../common.schema'

export const AccountLinkFailureSchema = z.object({
  event: z.literal('account_link_failure'),

  event_data: eventDataSchema,
  error_data: errorDataSchema,
})

export type AccountLinkFailureEvent = z.infer<typeof AccountLinkFailureSchema>
