import { z } from 'zod'
import { contentDataSchema, eventDataSchema } from '../../common.schema'

export const MediaUploadSuccessSchema = z.object({
  event: z.literal('media_upload_success'),

  content_data: contentDataSchema,
  event_data: eventDataSchema,
})

export type MediaUploadSuccessEvent = z.infer<typeof MediaUploadSuccessSchema>
