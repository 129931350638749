export const workSearchTerms = [
  'job',
  'work',
  'colleague',
  'promotion',
  'work anniversary',
  'manager',
  'business',
  'work birthday',
  'employee',
]
