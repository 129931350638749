import React, { FC } from 'react'
import { system as s } from '@moonpig/launchpad-system'
import { styled } from '@moonpig/launchpad-utils'
import { Flex, Text } from '@moonpig/launchpad-components'
import { IconSystemSearch } from '@moonpig/launchpad-assets'
import { InspirationItemType } from '../types'
import { useLocaleText } from '../../locale'
import { LIST_ICON_SIZE_PX } from '../constants'

const StyledInspiration = styled.li`
  cursor: pointer;
  display: flex;
  align-items: center;
  ${s({
    bgcolor: 'colorBackground01',
    border: '1px solid',
    borderColor: 'colorBorder03',
    borderRadius: 1,
    color: 'colorTextLabel',
    flex: { xs: '1 1 1' },
  })}
  &:hover,
  &:focus {
    ${s({ bgcolor: 'colorBackground02' })}
  }

  &:active {
    ${s({ bgcolor: 'colorBackground04' })}
  }
`

const StyledIconWrapper = styled(Flex)`
  align-items: center;
  height: ${LIST_ICON_SIZE_PX};
  ${s({
    borderRadius: '8px',
    pt: 4,
    pb: 4,
    pl: 5,
    pr: 4,
    color: 'colorInteractionControl',
  })}
`

const StyledTextWrapper = styled(Flex)`
  ${s({
    flexDirection: { xs: 'column', md: 'row' },
    pt: 4,
    pb: 4,
    pr: 6,
  })}
`
const StyledDepartmentText = styled(Text)`
  ${s({
    m: { xs: 0, md: 2 },
    lineHeight: '24px',
    color: 'colorInteractionControl',
  })}
`

const StyledText = styled(Text)`
  ${s({
    m: { xs: 0, md: 2 },
    color: 'textLabel',
    lineHeight: '24px',
  })}
`

export type DepartmentInspirationType = {
  department: string
  searchTerm: string
  value: string
}

type DepartmentInspirationProps = {
  item: DepartmentInspirationType
  itemIndex: number
  getItemProps: (props: {
    itemIndex: number
    item: InspirationItemType
  }) => Record<string, unknown>
} & React.HTMLProps<HTMLElement>

export const DepartmentInspiration: FC<DepartmentInspirationProps> = ({
  item,
  itemIndex,
  getItemProps,
}) => {
  const t = useLocaleText()
  const capitalizedSearchTerm = `${item.searchTerm[0].toUpperCase()}${item.searchTerm?.slice(
    1,
  )}`

  return (
    <StyledInspiration
      tabIndex={0}
      aria-label={`${t('search.search_for')} ${capitalizedSearchTerm}`}
      {...getItemProps({
        itemIndex,
        item: { ...item, itemType: 'inspiration' },
      })}
      data-search-term={item.searchTerm}
      data-item-type="inspiration"
      data-parent-department={item.department}
    >
      <StyledIconWrapper>
        <IconSystemSearch
          width={LIST_ICON_SIZE_PX}
          height={LIST_ICON_SIZE_PX}
        />
      </StyledIconWrapper>
      <StyledTextWrapper>
        <StyledText>{capitalizedSearchTerm}</StyledText>
        <StyledDepartmentText>
          {mapDepartmentToTitle(item.department)}
        </StyledDepartmentText>
      </StyledTextWrapper>
    </StyledInspiration>
  )
}

const mapDepartmentToTitle = (department: string) => {
  switch (department) {
    case 'ALL_CARDS':
      return 'Cards'

    case 'ALL_GIFTS':
      return 'Gifts'

    case 'ALL_FLOWERS_AND_PLANTS':
      return 'Flowers'
  }
}
