import { useMutation } from '@moonpig/web-core-graphql'
import { useCallback, useState } from 'react'
import {
  Source,
  Occasion,
} from '@moonpig/web-shared-types-graphql/graphqlTypes'
import { logger } from '@moonpig/web-core-monitoring'
import { ReminderInput, reminderInputSchema } from '../types'
import { CreateReminderGQL } from '../gql'
import { trackCreateReminder, trackErrorEvent } from '../analytics'
import { CreateReminderCommonLoyaltyMutation } from '../gql/__generated__/createReminder'

export type UseCreateReminderResult = {
  createReminder: (
    fields: ReminderInput,
    trackingIndex?: string,
  ) => Promise<CreateReminderResult>
  isCreating: boolean
  createReminderError: CreateReminderErrorReason | null
  resetCreateReminderState: () => void
}

export type CreateReminderResult =
  | {
      type: 'success'
      reminder: ReminderInput
    }
  | {
      type: CreateReminderErrorReason
    }

type CreateReminderErrorReason =
  | 'reminder-exists-error'
  | 'validation-error'
  | 'error'

const getFormattedError = (typename?: string): CreateReminderErrorReason => {
  switch (typename) {
    case 'ReminderAlreadyExists':
      return 'reminder-exists-error'
    case 'BadRequest':
      return 'validation-error'
    default:
      return 'error'
  }
}

export const useCreateReminder = (source?: Source): UseCreateReminderResult => {
  const [createReminderError, setCreateReminderError] =
    useState<CreateReminderErrorReason | null>(null)
  const [performCreateReminder, { loading: isCreating, reset }] =
    useMutation<CreateReminderCommonLoyaltyMutation>(CreateReminderGQL)

  const createReminder = async (
    fields: ReminderInput,
    trackingIndex?: string,
  ): Promise<CreateReminderResult> => {
    try {
      const input = reminderInputSchema.parse({
        ...fields,
        relationship: fields.relationship || null,
        source: source || Source.WEB_UNKNOWN,
      })

      const response = await performCreateReminder({
        mutation: CreateReminderGQL,
        variables: { input },
      })
      const { data } = response

      if (data?.createReminder?.__typename === 'CreatedReminder') {
        const eventReminderData = {
          index: trackingIndex || '1/0',
          origin: source || Source.WEB_UNKNOWN,
          occasion: input.occasion as Occasion,
          occasionDate: input.occasionDate,
        }

        trackCreateReminder(eventReminderData)

        return {
          type: 'success',
          reminder: { ...fields, id: data?.createReminder.id },
        }
      }
      const errorReason = getFormattedError(data?.createReminder?.__typename)
      setCreateReminderError(errorReason)
      trackErrorEvent({
        source: source || Source.WEB_UNKNOWN,
        kind: 'REMINDER_CREATION_ERROR',
        errorMessage: errorReason,
      })
      return { type: errorReason }
    } catch (e) {
      logger.warning(
        'Unable to create reminder',
        { source: source || Source.WEB_UNKNOWN },
        e,
      )

      trackErrorEvent({
        source: source || Source.WEB_UNKNOWN,
        kind: 'REMINDER_CREATION_ERROR',
        errorMessage: e.message?.substring(0, 500),
      })

      setCreateReminderError('error')
      return { type: 'error' }
    }
  }

  const resetCreateReminderState = useCallback(() => {
    reset()
    setCreateReminderError(null)
  }, [reset])

  return {
    createReminder,
    isCreating,
    createReminderError,
    resetCreateReminderState,
  }
}
