import AWSXRay, { Subsegment } from 'aws-xray-sdk'
import { LambdaMetrics } from '@moonpig/common-monitoring-lambda'
import { logger } from '../logger'

type MetricDimensions = { [id: string]: string }
type TraceAnnotations = { [id: string]: string | number | boolean }
export type MetricsCountOptions = {
  metricName: string
  metricDimensions?: MetricDimensions
  metricCount?: number
}
export type MetricsTraceOptions = {
  traceName?: string
  traceAnnotations?: TraceAnnotations
  metricName: string
  metricDimensions?: MetricDimensions
  useXRay?: boolean
}

export type Metrics = {
  count: (options: MetricsCountOptions) => void
  traceAsync: <TResult>(
    options: MetricsTraceOptions,
    fn: () => Promise<TResult>,
  ) => Promise<TResult>
  settlePromises: () => Promise<('rejected' | 'fulfilled')[]>
  traceId: () => { header: string; value: string | undefined }
}

const toError = (error: unknown): Error => {
  if (error instanceof Error) {
    return error
  }
  /* istanbul ignore next */
  return new Error(`Unknown error: ${error}`)
}

/* Update to true to test tracing locally */
const ENABLE_LOG_OVERRIDE = false
const enableLogging = () =>
  ENABLE_LOG_OVERRIDE || process.env.ENABLE_LOCAL_METRICS_LOG === 'true'

export const createMetrics = (params?: { useXRay: boolean }): Metrics => {
  const pendingPromises: Promise<void>[] = []
  const useXRay = params?.useXRay ?? true

  const isLambda = Boolean(process.env.LAMBDA_TASK_ROOT)

  const enableLog = enableLogging()
  const enableCloudWatchMetrics =
    process.env.ENABLE_CLOUDWATCH_METRICS === 'true' &&
    LambdaMetrics &&
    isLambda
  const enableXRay = AWSXRay && isLambda && useXRay

  if (enableXRay) {
    // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
    AWSXRay.captureHTTPsGlobal(require('https'))
  }

  const metricsLogger = enableCloudWatchMetrics
    ? new LambdaMetrics({
        metricsNamespace:
          process.env.APP_NAME || /* istanbul ignore next */ 'web',
        metricsDimensions: {
          'function-name':
            process.env.AWS_LAMBDA_FUNCTION_NAME ||
            /* istanbul ignore next */ 'unknown',
        },
      })
    : undefined

  const settlePromises = async () => {
    // This is called from Node.js only so Promise.allSettled is safe to use
    // eslint-disable-next-line compat/compat
    const results = await Promise.allSettled(Array.from(pendingPromises))
    return results.map(result => result.status)
  }

  const count = ({
    metricName,
    metricDimensions = {},
    metricCount: n = 1,
  }: {
    metricName: string
    metricDimensions?: { [id: string]: string }
    metricCount?: number
  }) => {
    if (enableCloudWatchMetrics && metricsLogger) {
      const promise = metricsLogger
        .withDimensionSets(metricDimensions)
        .count(`${metricName}-count`, n)
      pendingPromises.push(promise)
    }

    if (enableLog) {
      logger.info(`count ${metricName} ${JSON.stringify(metricDimensions)}`)
    }
  }

  const traceAsync = <TResult>(
    {
      traceName,
      traceAnnotations,
      metricName,
      metricDimensions = {},
    }: {
      traceName?: string
      traceAnnotations?: TraceAnnotations
      metricName: string
      metricDimensions?: MetricDimensions
    },
    fn: () => Promise<TResult>,
  ): Promise<TResult> => {
    return new Promise((resolve, reject) => {
      const capture = (xRaySubsegment?: Subsegment) => {
        if (traceAnnotations) {
          Object.entries(traceAnnotations).forEach(([key, value]) => {
            if (xRaySubsegment) {
              xRaySubsegment.addAnnotation(key, value)
            }
          })
        }

        const start = Date.now()

        const close = (err?: Error) => {
          const end = Date.now()
          const took = end - start

          const error = err ? toError(err) : undefined

          if (enableCloudWatchMetrics && metricsLogger) {
            const promise = metricsLogger
              .withDimensionSets({
                ...metricDimensions,
                error: error ? 'true' : 'false',
              })
              .durationSince(`${metricName}-duration`, start)
            pendingPromises.push(promise)
          }

          if (xRaySubsegment) {
            if (error) {
              xRaySubsegment.addFaultFlag()
              xRaySubsegment.addError(error)
            }
            xRaySubsegment.close()
          }

          if (enableLog) {
            logger.info(
              `trace ${traceName ?? metricName} took ${took}ms${
                error ? `, error "${error}"` : ''
              }`,
            )
          }
        }

        fn()
          .then(async result => {
            close()
            resolve(result)
          })
          .catch(error => {
            close(error)
            reject(error)
          })
      }

      return enableXRay && useXRay && traceName
        ? AWSXRay.captureAsyncFunc(traceName, capture)
        : capture()
    })
  }

  return {
    count,
    traceAsync,
    settlePromises,
    traceId: (): { header: string; value: string | undefined } => {
      return {
        header: 'X-Amzn-Trace-Id',
        value: process.env._X_AMZN_TRACE_ID,
      }
    },
  }
}
