import { trackGAEvent } from '@moonpig/web-core-analytics'

export const trackShopGAEvent = () => {
  trackGAEvent({
    event: 'select_content',
    event_data: {
      category: 'account event reminder',
      action: 'click',
      label: 'send a card',
      non_interaction: true,
      value: 1,
    },
  })
}
