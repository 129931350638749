import React from 'react'
import { Box } from '@moonpig/launchpad-components'

type Props = {
  htmlString: string
  as?: 'div' | 'span'
}

export const Markup: React.FC<Props> = ({ htmlString, as = 'div' }) => (
  // eslint-disable-next-line react/no-danger
  <Box dangerouslySetInnerHTML={{ __html: htmlString }} as={as} />
)
