import { z } from 'zod'
import { cookieConsentDataSchema, eventDataSchema } from '../common.schema'

export const CookieConsentSchema = z.object({
  event: z.literal('cookie_consent'),

  consent_data: cookieConsentDataSchema.optional(),
  event_data: eventDataSchema.optional(),
})

export type CookieConsentEvent = z.infer<typeof CookieConsentSchema>
