import { ZodIssue } from 'zod'

type FormattedError = {
  key: string
  error_type: string
  criteria: string
  expected: string
  received: string
}

export const errorFormatter = (e: ZodIssue): FormattedError => {
  const criteria =
    e.code === 'invalid_union_discriminator' ? 'Invalid event name' : e.message

  const expected = 'expected' in e ? (e.expected as string) : ''
  let received = ''

  if ('received' in e) {
    received = e.received as string
  } else if ('params' in e) {
    received = e.params?.received
  }

  return {
    key: e.path.join('.'),
    error_type: e.code,
    criteria,
    expected,
    received,
  }
}

export const printOutErrors = /* istanbul ignore next */ ({
  errors,
  result,
}: {
  errors: unknown[]
  result: unknown
}): void => {
  // eslint-disable-next-line no-console
  console.groupCollapsed(
    '%cGA validation error:',
    'color: red; font-weight: bold; background: yellow',
  )
  // eslint-disable-next-line no-console
  console.log('Errors:\n', errors)
  // eslint-disable-next-line no-console
  console.log('Original event:\n', result)
  // eslint-disable-next-line no-console
  console.log(new Error().stack)
  // eslint-disable-next-line no-console
  console.groupEnd()
}
