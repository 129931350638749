import React, { createContext, useContext } from 'react'

export type Experiments = {
  [key: string]: string
}

export const ExperimentsContext = createContext<Experiments>({})

type ExperimentsProviderProps = {
  children: React.ReactNode
  experiments: Experiments
}

export const ExperimentsProvider = ({
  children,
  experiments,
}: ExperimentsProviderProps) => (
  <ExperimentsContext.Provider value={experiments}>
    {children}
  </ExperimentsContext.Provider>
)

export const useExperiment = (key: string, fallback?: string) => {
  const experiments = useContext(ExperimentsContext)
  const variant = experiments[key]

  return variant || fallback
}

export const useAllSitespectExperiments = (): Experiments => {
  return useContext(ExperimentsContext)
}
