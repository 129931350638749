import { UseExperiment, useExperiment } from '../useExperiment/useExperiment'

export const useExperimentString: UseExperiment<string> = (
  experimentKey,
  { fallback, subjectAttributes },
) => {
  return useExperiment(
    experimentKey,
    fallback,
    ({ client, sessionId, defaultAttributes, override }) => {
      if (override) return override
      return client.getStringAssignment(
        experimentKey,
        sessionId,
        { ...defaultAttributes, ...subjectAttributes, shouldLog: true },
        fallback,
      )
    },
  )
}
