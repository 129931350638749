export const loginMethods = [
  'email',
  'google',
  'apple',
  'google one tap',
  'password',
  'passcode',
  'magic link',
  'passcode recovery',
] as const
