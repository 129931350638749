import React, { useCallback, useEffect } from 'react'
import {
  Box,
  PrimaryButton,
  Heading,
  Modal,
  Text,
} from '@moonpig/launchpad-components'
import { useLoginModal } from '@moonpig/web-core-auth'
import { redirect } from '@moonpig/web-core-routing'
import { useStoreId } from '@moonpig/web-core-stores'
import { trackGAEvent } from '@moonpig/web-core-analytics'
import { useLocaleText } from './LoginModal.locale'

const trackLoginClick = () => {
  trackGAEvent({
    event: 'select_content',
    content_data: {
      content_type: 'checkout | login | modal | please login to continue',
    },
    event_data: {
      category: 'checkout',
      action: 'login',
      label: 'modal  | please login to continue',
      non_interaction: true,
      value: undefined,
    },
    error_data: undefined,
    discount_data: undefined,
    ecommerce: undefined,
  })
}

const trackExpiredAuthenticationError = () => {
  trackGAEvent({
    event: 'login_error',
    user_data: {
      is_logged_in: false,
      login_method: 'email',
    },
    event_data: {
      category: 'checkout',
      action: 'session expired error',
      label: 'modal | please login to continue',
      non_interaction: true,
      value: undefined,
    },
    error_data: {
      id: 1,
      message: 'session expired',
    },
  })
}

export const LoginModal: React.FC = () => {
  const { show, setShow } = useLoginModal()
  const storeId = useStoreId()
  const t = useLocaleText()

  useEffect(() => {
    if (show) trackExpiredAuthenticationError()
  }, [show])

  const handleSubmit = useCallback<React.FormEventHandler<HTMLFormElement>>(
    event => {
      trackLoginClick()
      event.preventDefault()
      setShow(false)
      redirect({
        path: `/${storeId}/account/login/`,
        returnUrl: window.location.pathname + window.location.search,
      })
    },
    [setShow, storeId],
  )

  const message = t('login_expired_message')
  const signIn = t('sign_in')
  const title = t('login_expired_title')
  return (
    <Modal label={message} isOpen={show} hasCloseButton={false} mobilePagePeek>
      <Box justifyContent="center" width={{ xs: '100vw', md: 1 }} p={10}>
        <form onSubmit={handleSubmit} method="get">
          <Heading
            level="h2"
            typography={{
              xs: 'typeMobileDisplay03',
              md: 'typeDesktopDisplay04',
            }}
            textAlign="center"
            mb={3}
          >
            {title}
          </Heading>
          <Text as="div" textAlign="center" mb={6}>
            {message}
          </Text>
          <PrimaryButton mb={1} type="submit" width={1}>
            {signIn}
          </PrimaryButton>
        </form>
      </Box>
    </Modal>
  )
}
