/* istanbul ignore file */
import React, { FC, memo, useContext } from 'react'
import { styled, breakpoint, breakpointDown } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'

import { HeaderHeightContext } from '../HeaderWrapper'
import {
  NAV_BP,
  TRANSITON_TIME_MS,
  COLOR_KEYLINE,
  KEYLINE_SIZE_PX,
  MOBILE_NAV_WIDTH_PX,
} from '../constants'
import { DropdownContent } from './DropdownContent'
import { useMegaNavContext } from './MegaNavContext'
import { MegaNavItemProps } from './types'
import { SEOHiddenNavItems } from './SEOHiddenNavItems'

const SITE_WIDTH_INCLUDING_PADDING_PX = '1200px'

/*
  The following properties are set to fix a rendering bug in Safari.
  - width
  - overflow
*/

export const StyledDropdown = styled.div<{
  isOpen: boolean
  areAnyDropdownsOpen: boolean
  heightOffset: number
  windowHeight: number
  headerHeight: number
}>`
  z-index: 1;
  position: absolute;
  display: none;

  ${breakpointDown(NAV_BP)} {
    top: 0;
    ${({ windowHeight, heightOffset, headerHeight, isOpen }) => {
      const heightValue = `${windowHeight - (heightOffset + headerHeight)}px`
      return `
        min-height: ${isOpen ? heightValue : 0};
        height: ${isOpen ? 'auto' : heightValue};
      `
    }}
    transform: translateX(${MOBILE_NAV_WIDTH_PX});
    transition: transform ${TRANSITON_TIME_MS} 0ms,
      width 0ms ${TRANSITON_TIME_MS};
    border-left: ${KEYLINE_SIZE_PX} solid ${COLOR_KEYLINE};
    width: 0;
    overflow: hidden;
    ${s({ bgcolor: 'colorBackground01' })}

    &.is-open,
    &:focus-within {
      display: block;
      z-index: 2;
      width: ${MOBILE_NAV_WIDTH_PX};
      transform: translateX(0);
      transition: transform ${TRANSITON_TIME_MS} 0ms, width 0ms 0ms;
    }
  }

  ${breakpoint(NAV_BP)} {
    top: 100%;
    left: -1000%;
    width: ${SITE_WIDTH_INCLUDING_PADDING_PX};
    margin-top: ${KEYLINE_SIZE_PX};
    opacity: 0;
    transition: all 0ms ${TRANSITON_TIME_MS}, opacity ${TRANSITON_TIME_MS} 0ms;

    &.is-open,
    &:focus-within {
      display: block;
      left: 0;
      transition: all 0ms 0ms, opacity ${TRANSITON_TIME_MS} 0ms;
    }

    &.has-open-dropdown {
      opacity: 1;
      transition-delay: 0ms;
      display: block;
    }
  }
`

type DropdownProps = {
  index: number
  label: string
  href: string
  buttonAs?:
    | keyof JSX.IntrinsicElements
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    | React.ComponentType<React.PropsWithChildren<any>>
  items: MegaNavItemProps['dropdown']
  isDesktopNav?: boolean
}

type WrappedDropdownProps = DropdownProps & {
  isOpen: boolean
  areAnyDropdownsOpen: boolean
  heightOffset: number
  windowHeight: number
}

const WrappedDropdown: FC<React.PropsWithChildren<WrappedDropdownProps>> = memo(
  ({
    index: dropdownIndex,
    items,
    label,
    href,
    isOpen,
    areAnyDropdownsOpen,
    buttonAs,
    heightOffset,
    windowHeight,
  }) => {
    const headerHeight = useContext(HeaderHeightContext)

    return (
      <StyledDropdown
        aria-hidden={!isOpen}
        isOpen={isOpen}
        windowHeight={windowHeight}
        headerHeight={headerHeight}
        heightOffset={heightOffset}
        className={`${isOpen ? 'is-open' : ''} ${
          areAnyDropdownsOpen ? 'has-open-dropdown' : ''
        }`}
        data-testid={`lp-nav-dropdown-${dropdownIndex}`}
        areAnyDropdownsOpen={areAnyDropdownsOpen}
      >
        {isOpen ? (
          <DropdownContent
            dropdownIndex={dropdownIndex}
            isOpen={isOpen}
            label={label}
            href={href}
            buttonAs={buttonAs}
            items={items}
          />
        ) : (
          <SEOHiddenNavItems items={items} />
        )}
      </StyledDropdown>
    )
  },
)

WrappedDropdown.displayName = 'WrappedDropdown'

export const DropdownDesktop: FC<React.PropsWithChildren<DropdownProps>> = ({
  children,
  ...props
}) => {
  const [state] = useMegaNavContext()
  const {
    openDropdownIndex,
    isDesktopNav,
    dropdownHeightOffset,
    windowHeight,
  } = state
  // eslint-disable-next-line react/destructuring-assignment
  const isOpen = openDropdownIndex === props.index
  const areAnyDropdownsOpen = openDropdownIndex !== -1
  return (
    <WrappedDropdown
      {...{
        ...props,
        isOpen,
        windowHeight,
        areAnyDropdownsOpen: !!isDesktopNav && areAnyDropdownsOpen,
        isDesktopNav,
        heightOffset: dropdownHeightOffset,
      }}
    >
      {children}
    </WrappedDropdown>
  )
}
