import { z } from 'zod'
import {
  basketDataSchema,
  productDataSchema,
  screenDataSchema,
  userDataSchema,
  zodScreenNameString,
} from '../common.schema'

export const ScreenViewSchema = z.object({
  event: z.literal('screen_view'),
  screen_type: zodScreenNameString,
  screen_name: zodScreenNameString,

  basket_data: basketDataSchema.partial().optional(),
  user_data: userDataSchema.optional(),
  screen_data: screenDataSchema.optional(),
  product_data: productDataSchema.optional(),
  application_data: z
    .object({
      datalayer_version: z.literal(1),
    })
    .optional(),
})

export type ScreenViewEvent = z.infer<typeof ScreenViewSchema>
