import { EppoClient, IAssignmentEvent } from '@eppo/js-client-sdk-common'
import { DefaultSubjectAttributes } from '../../shared/DefaultSubjectAttributes'

type Attributes = {
  [key: string]: string | number | boolean
}

export type GetExperimentVariantParams = {
  subjectAttributes?: Attributes
}

type GetExperimentArgs<T> = {
  fallback: T
  subjectAttributes?: Attributes
}

export type GetExperiment<T> = (
  experimentKey: string,
  { fallback, subjectAttributes }: GetExperimentArgs<T>,
) => T

export const setupGetExperimentVariant = ({
  client,
  subjectKey,
  defaultAttributes,
  overrides,
}: {
  client: EppoClient | null
  subjectKey: string
  defaultAttributes: DefaultSubjectAttributes
  overrides: { [key: string]: string }
}) => {
  const trackedServerExperiments: Record<
    string,
    string | boolean | number | object
  > = {}

  client?.setAssignmentLogger({
    logAssignment: (assignmentEvent: IAssignmentEvent) => {
      if (!assignmentEvent.experiment) return
      if (!assignmentEvent.variation) return
      trackedServerExperiments[assignmentEvent.experiment] =
        assignmentEvent.variation
    },
  })

  const getExperimentString: GetExperiment<string> = (
    experimentKey: string,
    { fallback, subjectAttributes },
  ) => {
    if (!client) return fallback
    if (overrides[experimentKey]) return overrides[experimentKey]

    return client.getStringAssignment(
      experimentKey,
      subjectKey,
      { ...defaultAttributes, ...subjectAttributes },
      fallback,
    )
  }

  const getExperimentBoolean: GetExperiment<boolean> = (
    experimentKey: string,
    { fallback, subjectAttributes },
  ) => {
    if (!client) return fallback
    if (overrides[experimentKey]) return overrides[experimentKey] === 'true'

    return client.getBooleanAssignment(
      experimentKey,
      subjectKey,
      { ...defaultAttributes, ...subjectAttributes },
      fallback,
    )
  }

  const getExperimentJson: GetExperiment<object> = (
    experimentKey: string,
    { fallback, subjectAttributes },
  ) => {
    if (!client) return fallback
    if (overrides[experimentKey]) return JSON.parse(overrides[experimentKey])

    return client.getJSONAssignment(
      experimentKey,
      subjectKey,
      { ...defaultAttributes, ...subjectAttributes },
      fallback,
    )
  }

  const getExperimentInteger: GetExperiment<number> = (
    experimentKey: string,
    { fallback, subjectAttributes },
  ) => {
    if (!client) return fallback
    if (overrides[experimentKey]) return Number(overrides[experimentKey])

    return client.getIntegerAssignment(
      experimentKey,
      subjectKey,
      { ...defaultAttributes, ...subjectAttributes },
      fallback,
    )
  }

  const getExperimentNumeric: GetExperiment<number> = (
    experimentKey: string,
    { fallback, subjectAttributes },
  ) => {
    if (!client) return fallback
    if (overrides[experimentKey]) return Number(overrides[experimentKey])

    return client.getNumericAssignment(
      experimentKey,
      subjectKey,
      { ...defaultAttributes, ...subjectAttributes },
      fallback,
    )
  }

  return {
    getExperimentString,
    getExperimentBoolean,
    getExperimentJson,
    getExperimentInteger,
    getExperimentNumeric,
    trackedServerExperiments,
  }
}
