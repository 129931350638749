import { FunctionComponent, useEffect } from 'react'
import { ApolloClient, useApolloClient } from '@apollo/client'
import { gql, GraphQLContext } from '@moonpig/web-core-graphql'
import {
  destroyBrowserCookie,
  getBrowserCookies,
  setBrowserCookie,
} from '@moonpig/web-core-cookies'
import { isLoggedIn } from '@moonpig/web-core-auth'
import { useCoreFlag } from '@moonpig/web-core-flags'

type UserDataQuery = {
  me: {
    customer: {
      id: string
      crmDetails: {
        id: string
        cookie: string | null
      }
    }
  }
}

const userCrmDetailsQuery = gql`
  query UserCrmIdQuery {
    me {
      __typename
      customer {
        id
        crmDetails {
          id
          cookie
        }
      }
    }
  }
`

const queryUserData = async (
  query: ApolloClient<GraphQLContext>['query'],
): Promise<{
  sailthruId: string
  sailthruCookie: string | null
}> => {
  const result = await query<UserDataQuery>({
    query: userCrmDetailsQuery,
    errorPolicy: 'all',
  })
  const { data, errors } = result

  if ((errors && errors.length) || !data.me.customer.crmDetails) {
    return {
      sailthruId: '',
      sailthruCookie: '',
    }
  }

  const { customer } = data.me

  return {
    sailthruId: customer.crmDetails.id,
    sailthruCookie: customer.crmDetails.cookie,
  }
}

export const removeSailthruCookie = () => {
  destroyBrowserCookie('mnpg_sailthru_id', {
    path: '/',
  })
  destroyBrowserCookie('sailthru_hid', {
    path: '/',
  })
}

const checkTargetingCookie = (cookieString: string) => {
  if (!cookieString) return false

  const oneTrustTargetingGroup = 'C0004:1'
  return cookieString.includes(oneTrustTargetingGroup)
}

export const CreateSailthruCookie: FunctionComponent = () => {
  const client = useApolloClient()
  const cookies = getBrowserCookies()
  const oneTrust = cookies.OptanonConsent

  const isTargetingCookieEnabled = checkTargetingCookie(oneTrust)
  const isEnabled =
    useCoreFlag('enable-sailthru') &&
    isLoggedIn(cookies) &&
    (!cookies.mnpg_sailthru_id || !cookies.sailthru_hid) &&
    isTargetingCookieEnabled

  useEffect(() => {
    const send = async () => {
      if (isEnabled) {
        const { sailthruId, sailthruCookie } = await queryUserData(client.query)
        if (sailthruId) {
          setBrowserCookie('mnpg_sailthru_id', sailthruId, {
            path: '/',
            maxAge: 60 * 60 * 24 * 30 * 6,
          })
        }
        if (sailthruCookie) {
          setBrowserCookie('sailthru_hid', sailthruCookie, {
            path: '/',
            maxAge: 60 * 60 * 24 * 30 * 6,
          })
        }
      }
    }
    send()
  }, [cookies, client.query, isEnabled])

  return null
}
