import { z } from 'zod'
import {
  eventDataSchema,
  userDataSchema,
  zodAddressCountString,
  zodBoolean,
} from '../../common.schema'

export const AddRecipientSchema = z.object({
  event: z.literal('add_recipient'),

  user_data: userDataSchema.extend({
    address_count: zodAddressCountString,
    is_logged_in: zodBoolean.optional(),
  }),

  event_data: eventDataSchema,
})

export type AddRecipientEvent = z.infer<typeof AddRecipientSchema>
