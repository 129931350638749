import { z } from 'zod'
import {
  eventDataSchema,
  resultsDataSchema,
  zodNumber,
  zodResultsDataSuggestionTypeEnum,
} from '../common.schema'

export const SearchSuggestionSchema = z.object({
  event: z.literal('search_suggestion'),

  event_data: eventDataSchema,
  results_data: resultsDataSchema.extend({
    index: zodNumber
      .int()
      .positive({
        message:
          'Item index, if specified, should be greater than or equal to 1',
      })
      .optional(),
    suggestion_type: zodResultsDataSuggestionTypeEnum
      .or(z.enum(['search', 'reminders']))
      .optional(),
  }),
})

export type SearchSuggestionEvent = z.infer<typeof SearchSuggestionSchema>
