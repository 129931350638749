import { sanitizeStrings, trackGAEvent } from '@moonpig/web-core-analytics'
import {
  Occasion,
  Source,
} from '@moonpig/web-shared-types-graphql/graphqlTypes'

const sanitize = (str: string) => {
  return sanitizeStrings(str, { forwardSlash: true })
}

export const trackImpression = ({
  occasions,
  source,
}: {
  occasions: Occasion[]
  source: Source
}) => {
  trackGAEvent({
    event: 'view_item_list',

    ecommerce: {
      value: 0,
      currency: 'GBP',
      items: occasions.map((occasion, index) => ({
        index: index + 1,
        item_list_name: `${sanitize(source)}`,
        item_name: sanitize(occasion),
        quantity: 1,
        item_id: sanitize(occasion).replace(' ', '-'),
        price: 0,
      })),
    },

    event_data: {
      category: 'reminders',
      action: 'view item list',
      label: `${sanitize(source)}`,
      non_interaction: true,
      value: undefined,
    },
  })
}
