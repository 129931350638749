import type { Schema } from './types'

export const shared: Schema = {
  'digital-gift-card-options-free-ecard': {
    default: false,
  },
  'digi-gifts-dynamic-card-options': {
    default: false,
  },
  'digital-gifts-with-ecards': {
    default: false,
  },
  'gift-cards-with-ecards': {
    default: false,
  },
  'modular-digital-gift-pdp': {
    default: false,
  },
  'search-show-collage-maker': {
    default: true,
  },
  'enable-fav-toast-notification': {
    default: false,
  },
  'explore-enable-dynamic-hero': {
    default: false,
  },
  'loyalty-enable-member-pricing': {
    default: false,
  },
  'disable-moonpig-plus-pills': {
    default: false,
  },
  'search-use-new-filters-api': {
    default: false,
  },
  'search-product-type-v1': {
    default: false,
  },
  'search-state-management-refactor': {
    default: false,
  },
}
