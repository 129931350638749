type ObjectWithKey = {
  key: string
  [key: string]: unknown
}

const isArrayKeyPattern = (key: string): boolean => {
  return /ecommerce\.items\.\d+\./.test(key)
}

const normalizeArrayKey = (key: string): string => {
  return key.replace(/(ecommerce\.items\.)\d+(\..*)/, '$1*$2')
}

export const deduplicateValidationErrors = <T extends ObjectWithKey>(
  array: T[],
): T[] => {
  const seenPatterns = new Set<string>()

  return array.flatMap(item => {
    if (!isArrayKeyPattern(item.key)) {
      return [item]
    }

    const normalizedKey = normalizeArrayKey(item.key)
    if (!seenPatterns.has(normalizedKey)) {
      seenPatterns.add(normalizedKey)
      return [{ ...item, key: normalizedKey }]
    }

    return []
  })
}
