import React, { FC } from 'react'
import { RecentSearch } from './RecentSearch'
import { RecentSearchItemType, RecentSearchType } from '../types'

type RecentSearchesProps = {
  recentSearches: RecentSearchType[]
  getItemProps: (props: {
    itemIndex: number
    item: RecentSearchItemType
  }) => Record<string, unknown>
  getMenuProps: () => Record<string, unknown>
  onRemoveItem: (item: RecentSearchType) => void
}

export const RecentSearches: FC<RecentSearchesProps> = ({
  recentSearches,
  getItemProps,
  getMenuProps,
  onRemoveItem,
}) => {
  const validDepartments = ['all_cards', 'all_flowers_and_plants', 'all_gifts']
  const recentSearchesWithoutInvalidDepartments = recentSearches.filter(
    ({ department }) => validDepartments.includes(department.toLowerCase()),
  )
  return (
    <ul {...getMenuProps()} data-testid={'lp-nav-search-recent-searches'}>
      {recentSearchesWithoutInvalidDepartments.map((item, index) => {
        return (
          <RecentSearch
            key={`${item.department}-${item.searchTerm}`}
            item={item}
            itemIndex={index}
            getItemProps={getItemProps}
            onRemoveItem={onRemoveItem}
          />
        )
      })}
    </ul>
  )
}
