import { z } from 'zod'
import {
  eventDataSchema,
  userDataSchema,
  zodAddressCountString,
  zodLowerCaseString,
} from '../../common.schema'

export const CustomerSchema = z.object({
  event: z.literal('customer'),

  user_data: userDataSchema.required().extend({
    address_count: zodAddressCountString.optional(),
    email: zodLowerCaseString.optional(),
  }),
  event_data: eventDataSchema,
})

export type CustomerEvent = z.infer<typeof CustomerSchema>
