import { z } from 'zod'
import {
  contentDataSchema,
  eventDataSchema,
  productDataSchema,
} from '../../common.schema'

export const EditorPreviewSchema = z.object({
  event: z.literal('editor_preview'),

  content_data: contentDataSchema,
  event_data: eventDataSchema,
  product_data: productDataSchema,
})

export type EditorPreviewEvent = z.infer<typeof EditorPreviewSchema>
