import { createLocaleTextHook } from '@moonpig/web-core-locale-text'
import { daysBetween } from '@moonpig/web-core-utils'

const dictionary = {
  'breadcrumbs.breadcrumbs': {
    'en-GB': 'Breadcrumbs',
    'nl-NL': 'Breadcrumbs',
  },
  'checkout_header.secure_checkout': {
    'en-GB': 'Secure Checkout',
    'nl-NL': 'Veilig Afrekenen',
  },
  'credit_amount.credit_value': {
    'en-GB': (displayAmount: string) => `${displayAmount} credit`,
    'nl-NL': /* istanbul ignore next */ (displayAmount: string) =>
      `${displayAmount} tegoed`,
  },
  'credit_amount.modal_label': {
    'en-GB': 'Credit Amount Modal',
    'nl-NL': 'Venster Kredietbedrag',
  },
  'credit_amount.modal_title': {
    'en-GB': (displayAmount: string) => `You've got ${displayAmount} credit!`,
    'nl-NL': /* istanbul ignore next */ (displayAmount: string) =>
      `Je hebt ${displayAmount} tegoed!`,
  },
  'credit_amount.modal_description': {
    'en-GB':
      'It will be automatically applied to your basket when you’re ready to purchase.',
    'nl-NL': 'Dit wordt automatisch verrekend, zodra je bestelt.',
  },
  'credit_amount.shop_now': {
    'en-GB': 'Shop Now',
    'nl-NL': 'Kies je kaart',
  },
  'header.skip_to_content': {
    'en-GB': 'Skip to content',
    'nl-NL': 'Bekijk meer',
  },
  'utility_menu.count': {
    'en-GB': (count: number) => ` (${count} items)`,
    'nl-NL': /* istanbul ignore next */ (count: number) => ` (${count} items)`,
  },
  'notifier.notifications_modal': {
    'en-GB': 'Notifications Modal',
    'nl-NL': 'Meldingenvenster',
  },
  'notifier.notifications': {
    'en-GB': 'Notifications',
    'nl-NL': 'Meldingen',
  },
  'notifier.no_notifications': {
    'en-GB': 'You have no notifications.',
    'nl-NL': 'Je hebt geen meldingen.',
  },
  'notifier.reminders': {
    'en-GB': 'Reminders',
    'nl-NL': 'Kalender',
  },
  mega_nav: {
    'en-GB': 'Main',
    'nl-NL': 'Menu',
  },
  'mega_nav.close': {
    'en-GB': 'Close menu',
    'nl-NL': 'Menu sluiten',
  },
  'mega_nav.close_item': {
    'en-GB': (label: string) => `Close ${label}`,
    'nl-NL': /* istanbul ignore next */ (label: string) => `${label} sluiten`,
  },
  'mega_nav.primary_item': {
    'en-GB': (label: string) => `Open ${label} dropdown`,
    'nl-NL': /* istanbul ignore next */ (label: string) =>
      `Vervolgkeuzelijst ${label} openen`,
  },
  'mega_nav.dropdown_content.close': {
    'en-GB': (label: string) => `Close ${label} dropdown`,
    'nl-NL': /* istanbul ignore next */ (label: string) =>
      `Vervolgkeuzelijst ${label} sluiten`,
  },
  'mega_nav.dropdown_content.primary_link': {
    'en-GB': (label: string) => `All ${label}`,
    'nl-NL': /* istanbul ignore next */ (label: string) => `Alle ${label}`,
  },
  'mega_nav.sub_dropdown.open': {
    'en-GB': (title: string) => `Open ${title} menu`,
    'nl-NL': /* istanbul ignore next */ (title: string) =>
      `Menu ${title} openen`,
  },
  'mega_nav.sub_dropdown.close': {
    'en-GB': (title: string) => `Close ${title} menu`,
    'nl-NL': /* istanbul ignore next */ (title: string) =>
      `Menu ${title} sluiten`,
  },
  'mega_nav.sub_dropdown.category': {
    'en-GB': ' Category',
    'nl-NL': ' Categorie',
  },
  'mega_nav.toggle.close': {
    'en-GB': 'Close menu',
    'nl-NL': 'Menu sluiten',
  },
  'mega_nav.toggle.open': {
    'en-GB': 'Open menu',
    'nl-NL': 'Menu openen',
  },
  'mega_nav.toggle.title': {
    'en-GB': 'Menu',
    'nl-NL': 'Menu',
  },
  'header.logo_link_moonpig': {
    'en-GB': 'Go to Moonpig home',
    'nl-NL': 'Ga naar de homepagina van Moonpig',
  },
  'header.logo_link_greetz': {
    'en-GB': 'Go to Greetz home',
    'nl-NL': 'Ga naar de homepagina van Greetz',
  },
  'search.search': {
    'en-GB': 'Search',
    'nl-NL': 'Zoeken',
  },
  'search.placeholder': {
    'en-GB': 'Search…',
    'nl-NL': 'Zoeken…',
  },
  'search.close_search': {
    'en-GB': 'Close search',
    'nl-NL': 'Zoekfunctie sluiten',
  },
  'search.clear_search': {
    'en-GB': 'Clear search input',
    'nl-NL': 'Zoekopdracht wissen',
  },
  'search.submit_search': {
    'en-GB': 'Submit search',
    'nl-NL': 'Zoekopdracht uitvoeren',
  },
  'search.navigational_suggestions_header': {
    'en-GB': 'Services',
    'nl-NL': 'Hulp & Tips',
  },
  'search.recently_searched': {
    'en-GB': 'RECENTLY SEARCHED',
    'nl-NL': 'RECENT GEZOCHT',
  },
  'search.recent_searches': {
    'en-GB': 'Recent searches',
    'nl-NL': 'Recente zoekopdrachten',
  },
  'search.clear_recent_search': {
    'en-GB': 'clear recent search',
    'nl-NL': 'wis recente zoekopdracht',
  },
  'search.clear_all_recent_searches': {
    'en-GB': 'Clear All',
    'nl-NL': 'Wis Alle',
  },
  'search.clear_recent_searches': {
    'en-GB': 'Clear',
    'nl-NL': 'Wissen',
  },
  'search.clear_all_recent_searches_label': {
    'en-GB': 'Clear all recent searches',
    'nl-NL': 'Wis alle recente zoekopdrachten',
  },
  'search.suggestions': {
    'en-GB': 'SUGGESTIONS',
    'nl-NL': 'SUGGESTIES',
  },
  'search.trending': {
    'en-GB': 'TRENDING',
    'nl-NL': 'POPULAIR',
  },
  'search.suggested': {
    'en-GB': 'Suggested',
    'nl-NL': 'Voorgesteld',
  },
  'search.search_for': {
    'en-GB': 'search for',
    'nl-NL': 'zoeken naar',
  },
  'search.reminder.date': {
    'en-GB': ({ dateOfEvent }: { dateOfEvent: Date }) => {
      const daysUntilEvent = daysBetween(new Date(), dateOfEvent)

      if (daysUntilEvent === 1) {
        return 'is tomorrow'
      }

      if (daysUntilEvent === 0) {
        return 'is today'
      }

      return `in ${daysUntilEvent} days`
    },
    'nl-NL': /* istanbul ignore next */ ({
      dateOfEvent,
    }: {
      dateOfEvent: Date
    }) => {
      const daysUntilEvent = daysBetween(new Date(), dateOfEvent)

      if (daysUntilEvent === 1) {
        return 'morgen'
      }

      if (daysUntilEvent === 0) {
        return 'vandaag'
      }

      return `over ${daysUntilEvent} dagen`
    },
  },
  'search.search_term_in_department': {
    'en-GB': (c: { departmentTitle: string; searchTerm: string }) =>
      `Search "${c.searchTerm}" in ${c.departmentTitle}`,
    'nl-NL': (c: { departmentTitle: string; searchTerm: string }) =>
      `Zoek naar "${c.searchTerm}" in ${getNlDepartment(c.departmentTitle)}`,
  },
  'store_selector.deliver_to': {
    'en-GB': (countryName: string) => `Deliver to ${countryName}`,
    'nl-NL': /* istanbul ignore next */ (countryName: string) =>
      `Bezorgen in ${countryName}`,
  },
  'store_selector.deliver_to_screen_reader_only': {
    'en-GB': (countryName: string) =>
      `Change delivery destination from ${countryName}`,
    'nl-NL': /* istanbul ignore next */ (countryName: string) =>
      `Wijzig de bezorgbestemming vanuit ${countryName}`,
  },
  'store_selector.modal_label': {
    'en-GB': 'Store Selector Modal',
    'nl-NL': 'Winkelselectievenster',
  },
  'store_selector.heading': {
    'en-GB': 'Where should we send your order?',
    'nl-NL': 'Waar moeten we je bestelling naartoe sturen?',
  },
  'store_selector.done': {
    'en-GB': 'Done',
    'nl-NL': 'Klaar',
  },
  'footer.copyright': {
    'en-GB': (year: string) =>
      `© Moonpig.com Limited ${year}. Registered company address is Herbal House, 10 Back Hill, London EC1R 5EN, UK. A place close to your heart.`,
    'nl-NL': /* istanbul ignore next */ (year: string) =>
      `Copyright © ${year} by Greetz`,
  },
  'footer.nav.apps': {
    'en-GB': 'Our Apps',
    'nl-NL': 'Onze Apps',
  },
  'footer.nav.payment_methods': {
    'en-GB': 'Payment Methods',
    'nl-NL': 'Betaalmethodes',
  },
  'footer.nav.region': {
    'en-GB': 'Region',
    'nl-NL': 'Regio',
  },
  'footer.nav.region.selector': {
    'en-GB': 'Shop in the region you are sending to.',
    'nl-NL': 'Shop in de regio waar je je bestelling naartoe stuurt.',
  },
  'footer.nav.region.change': {
    'en-GB': (label: string) => `Change region to ${label}`,
    'nl-NL': /* istanbul ignore next */ (label: string) =>
      `Regio wijzigen in ${label}`,
  },
  'footer.nav.social': {
    'en-GB': 'Keep in Touch',
    'nl-NL': 'Onze socials',
  },
  'footer.nav.social.navigate': {
    'en-GB': (label: string) => `Navigate to Moonpig's ${label}`,
    'nl-NL': /* istanbul ignore next */ (label: string) =>
      `Naar Greetz ${label} gaan`,
  },
  'footer.nav.brands': {
    'en-GB': 'Our Brands',
    'nl-NL': 'Onze merken',
  },
}

const getNlDepartment = (department: string) => {
  switch (department.toLowerCase()) {
    case 'gifts':
      return 'cadeaus'
    case 'flowers & plants':
      return 'bloemen en planten'
    case 'cards':
    default:
      return 'kaarten'
  }
}

export const useLocaleText = createLocaleTextHook({
  dictionary,
})
