import { useSessionId, useEppoClient } from '@moonpig/web-core-utils'
import { useEffect, useMemo, useState } from 'react'
import { logger } from '@moonpig/web-core-monitoring'
import { EppoClient } from '@eppo/js-client-sdk-common'
import { getBrowserCookie } from '@moonpig/web-core-cookies'
import { useDefaultSubjectAttributes } from '../useDefaultSubjectAttributes/useDefaultSubjectAttributes'
import { useQueryStringOverride } from '../useQueryStringOverride/useQueryStringOverride'
import {
  Attributes,
  DefaultSubjectAttributes,
} from '../../shared/DefaultSubjectAttributes'

type UseExperimentParams<ReturnType> = {
  subjectKey?: string
  subjectAttributes?: Attributes
  fallback: ReturnType
}

export type UseExperiment<ReturnType> = (
  experimentKey: string,
  params: UseExperimentParams<ReturnType>,
) => ReturnType

type AssignmentFunction<ReturnType> = (args: {
  client: EppoClient
  defaultAttributes: DefaultSubjectAttributes
  sessionId: string
  override: string | null
}) => ReturnType

export const useExperiment = <ReturnType>(
  experimentKey: string,
  fallback: ReturnType,
  assignmentFunction: AssignmentFunction<ReturnType>,
) => {
  const [cookieOverride, setCookieOverride] = useState<string | null>(null)

  useEffect(() => {
    const cookieOverrides = getBrowserCookie('mnpg_eppo_overrides')
    if (cookieOverrides) {
      const experimentOverride =
        JSON.parse(cookieOverrides)[experimentKey] ?? null
      setCookieOverride(experimentOverride)
    }
  }, [setCookieOverride, experimentKey])

  const defaultAttributes = useDefaultSubjectAttributes()
  const queryStringOverride = useQueryStringOverride({ experimentKey })
  const { sessionId } = useSessionId()
  const client = useEppoClient()

  const override = queryStringOverride || cookieOverride

  const assignedVariation = useMemo(() => {
    if (!client) {
      logger.fixToday('useEppoExperiment: Client is not defined')
      return fallback
    }

    if (!sessionId) return fallback

    return assignmentFunction({
      client,
      defaultAttributes,
      sessionId,
      override,
    })
  }, [
    assignmentFunction,
    client,
    defaultAttributes,
    fallback,
    override,
    sessionId,
  ])

  return assignedVariation
}
