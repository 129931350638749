import { useEffect } from 'react'
import { ApolloClient, useApolloClient } from '@apollo/client'
import {
  getCustomerType,
  trackGAEvent,
  clearSendCustomerEvent,
  getSendCustomerEvent,
} from '@moonpig/web-core-analytics'
import { gql, GraphQLContext } from '@moonpig/web-core-graphql'
import { logger } from '@moonpig/web-core-monitoring'
import { useGoogleOneTap } from '@moonpig/web-core-auth'
import { setGACustomerData } from '@moonpig/web-core-analytics/src/gaCustomerData'

type UserDataQuery = {
  me: {
    customer: {
      id: string
      orders: {
        totalCount: number
      }
    }
  }
}

const userDataQuery = gql`
  query UserDataQuery {
    me {
      __typename
      customer {
        __typename
        id
        orders(limit: 1) {
          __typename
          totalCount
        }
      }
    }
  }
`

const queryUserData = async (
  query: ApolloClient<GraphQLContext>['query'],
): Promise<{
  id: string
  totalCount: number
}> => {
  const result = await query<UserDataQuery>({
    query: userDataQuery,
    errorPolicy: 'all',
  })
  const { data, errors } = result

  if (errors && errors.length) {
    throw new Error(errors.map(error => error.toString()).join(', '))
  }

  const { customer } = data.me

  return {
    id: customer.id,
    totalCount: customer.orders.totalCount,
  }
}

export const SendGAUserData = () => {
  const client = useApolloClient()
  const oneTapState = useGoogleOneTap()

  useEffect(() => {
    const send = async () => {
      try {
        const { sendEvent, loginMethod = 'password' } = getSendCustomerEvent()
        if (!sendEvent) {
          return
        }

        const userData = await queryUserData(client.query)
        setGACustomerData({
          ctId: userData.id,
          lifetimeOrderCount: userData.totalCount,
          loginMethod,
        })
        trackGAEvent({
          event: 'customer',
          user_data: {
            customer_id: userData.id,
            is_logged_in: true,
            lifetime_order_count: userData.totalCount,
            customer_type: getCustomerType(userData.totalCount),
            login_method: loginMethod as
              | 'google'
              | 'apple'
              | 'google one tap'
              | 'password'
              | 'passcode'
              | 'magic link',
          },
          event_data: {
            category: 'account',
            action: 'customer authentication',
            label: `${loginMethod} | ${userData.id}`,
            non_interaction: true,
            value: undefined,
          },
        })

        clearSendCustomerEvent()
      } catch (e) {
        logger.warning(`Could not send GA user data`, {}, e)
      }
    }

    send()
  }, [client.query, oneTapState])

  return null
}
