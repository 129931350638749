import React, { FC } from 'react'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import {
  DepartmentInspiration,
  DepartmentInspirationType,
} from './DepartmentInspiration'
import { InspirationItemType } from '../types'

type DepartmentInspirationListProps = {
  inspirations: DepartmentInspirationType[]
  getItemProps: (props: {
    itemIndex: number
    item: InspirationItemType
  }) => Record<string, unknown>
  getMenuProps: () => Record<string, unknown>
  startIndex?: number
}

const InspirationList = styled.ul`
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  ${s({
    mt: 3,
    ml: 6,
    mb: 4,
    gap: 8,
    p: 0,
  })}
`

export const DepartmentsInspirationList: FC<DepartmentInspirationListProps> = ({
  inspirations,
  getItemProps,
  getMenuProps,
  startIndex = 0,
}) => {
  return (
    <InspirationList
      {...getMenuProps()}
      data-testid={'lp-nav-search-inspirations-variant'}
    >
      {inspirations.map((item, index) => {
        return (
          <DepartmentInspiration
            key={`${item.department}-${item.searchTerm}`}
            item={item}
            itemIndex={startIndex + index}
            getItemProps={getItemProps}
          />
        )
      })}
    </InspirationList>
  )
}
