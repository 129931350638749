import { setSendCustomerEvent, trackGAEvent } from '@moonpig/web-core-analytics'

export const trackOneTapSuccessfulLogin = () => {
  trackGAEvent({
    event: 'login',

    event_data: {
      category: 'account',
      action: 'login success',
      label: 'google one tap',
      non_interaction: true,
      value: undefined,
    },
  })

  setSendCustomerEvent({ loginMethod: 'google one tap' })
}

export const trackOneTapSuccessfulRegistration = () => {
  trackGAEvent({
    event: 'sign_up',

    event_data: {
      category: 'account',
      action: 'sign up success',
      label: 'google one tap',
      non_interaction: true,
      value: undefined,
    },
  })

  setSendCustomerEvent({ loginMethod: 'google one tap' })
}

export const trackOneTapFailedLogin = (loggedIn: boolean) => {
  trackGAEvent({
    event: 'login_failure',

    event_data: {
      category: 'account',
      action: 'login error',
      label: 'google one tap',
      non_interaction: true,
      value: undefined,
    },

    user_data: {
      is_logged_in: loggedIn,
      login_method: 'google one tap',
    },

    error_data: {
      id: 0,
      message: 'login error',
    },
  })
}

export const trackOneTapAccountLinkRequired = () => {
  trackGAEvent({
    event: 'impression',
    content_data: {
      content_type: `account | google one tap | linking required`,
      item_id: undefined,
    },
    event_data: {
      category: 'account',
      action: 'google one tap login',
      label: `google one tap | linking required`,
      non_interaction: true,
      value: undefined,
    },
  })
}

export const trackOneTapAccountLinkSuccess = () => {
  trackGAEvent({
    event: 'account_link_success',

    event_data: {
      category: 'account',
      action: 'account link success',
      label: 'google one tap',
      non_interaction: true,
      value: undefined,
    },
  })
}

export const trackOneTapAccountLinkFailure = ({
  errorCode,
  errorMessage,
}: {
  errorCode?: number
  errorMessage: string
}) => {
  trackGAEvent({
    event: 'account_link_failure',

    event_data: {
      category: 'account',
      action: 'account link failure',
      label: 'google one tap',
      non_interaction: true,
      value: undefined,
    },

    error_data: {
      id: errorCode || '0',
      message: errorMessage,
    },
  })
}
