import { z } from 'zod'
import { eventDataSchema, remindersDataSchema } from '../../common.schema'

export const DeleteReminderSchema = z.object({
  event: z.literal('delete_reminder'),

  event_data: eventDataSchema,
  reminder_data: remindersDataSchema,
})

export type DeleteReminderEvent = z.infer<typeof DeleteReminderSchema>
