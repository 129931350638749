type Options = {
  forwardSlash?: boolean
  invalidCharacters?: boolean
}

export const sanitizeStrings = <T extends string | undefined>(
  str: T,
  options?: Options,
): T => {
  if (str === undefined) {
    return str
  }

  let str2 = str.toLocaleLowerCase().replace(/_|-/g, ' ')

  if (options?.forwardSlash) {
    str2 = str2.replace(/\//g, '')
  }

  if (options?.invalidCharacters) {
    str2 = str2.replace(/[^a-zA-Z0-9\s|]/g, '')
  }

  return str2 as T
}
